import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';



export function ifscCodeValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const ifscCodeRegex = /^[A-Za-z]{4}\d{7}$/; // Customize the regex based on your requirements
    const valid = ifscCodeRegex.test(control.value);
    return valid ? null : { 'invalidIfscCode': { value: control.value } };
  };
}

@Component({
  selector: 'app-addbankaccount',
  templateUrl: './addbankaccount.component.html',
  styleUrls: ['./addbankaccount.component.css']
})
export class AddbankaccountComponent {
  addBankAccountForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private service: ServicesService,
    private router: Router ,
    private dialogRef: MatDialogRef<AddbankaccountComponent> 
  ) {}

  ngOnInit(): void {
    this.addBankAccountForm = this.formBuilder.group({
      accountHolderName: ['', Validators.required],
      accountNumber: ['', Validators.required],
      bankName: ['', Validators.required],
      ifscCode: ['', [Validators.required, ifscCodeValidator()]],
    });
  }

  onSubmit() {
    if (this.addBankAccountForm.valid) {
      const formData = new FormData();
      formData.append('accountHolderName', this.addBankAccountForm.get('accountHolderName')?.value);
      formData.append('accountNumber', this.addBankAccountForm.get('accountNumber')?.value);
      formData.append('bankName', this.addBankAccountForm.get('bankName')?.value);
      formData.append('ifscCode', this.addBankAccountForm.get('ifscCode')?.value);

      this.service.saveBankDetails(formData)
        .subscribe(
          response => {
            console.log(response);
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Data saved successfully.',
            }).then(() => {
              localStorage.clear();
              this.router.navigate(['']);
              this.dialogRef.close(); 
            });
          },
          error => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Data not saving.',
            });
           
          }
        );
    }
  }


  
}
