import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../services.service';

interface Payment {
  fullName:string;
  paymentMethod:string;
  amount:string;
  paymentDate:string;
  transactionId: string;

}

interface PaymentWithCandidateDTO {
  fullName: string;
  paymentHistory: Payment[];
}

@Component({
  selector: 'app-getpaymenthistory',
  templateUrl: './getpaymenthistory.component.html',
  styleUrls: ['./getpaymenthistory.component.css']
})
export class GetpaymenthistoryComponent implements OnInit {

  candidateId!: number;
  paymentHistory: PaymentWithCandidateDTO = {
    fullName: '',
    paymentHistory: []
  };

  constructor(private route: ActivatedRoute, private candidateservice: ServicesService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const candidateIdParam = params.get('candidateId');
      if (candidateIdParam) {
        this.candidateId = +candidateIdParam;
        this.loadPaymentHistory();
      } else {
        console.error('Candidate ID parameter is missing.');
      }
    });
  }
  
  loadPaymentHistory(): void {
    this.candidateservice.getCandidatePaymentHistory(this.candidateId)
      .subscribe(
        data => {
          if (data) {
            this.paymentHistory = data;
          } else {
            console.error('Received null data from API.');
          }
        },
        error => {
          console.error('An error occurred:', error);
        }
      );
  }
  
}
