import { Component } from '@angular/core';
import Swal from 'sweetalert2';
import { ServicesService } from '../Clients/services.service';
import { HttpClient } from '@angular/common/http';
import { Livedemo } from '../livedemo';

@Component({
  selector: 'app-livedemo',
  templateUrl: './livedemo.component.html',
  styleUrls: ['./livedemo.component.css']
})
export class LivedemoComponent {
  searchText: string = ''; 
  demos: Livedemo[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 8;
  businessId: string = '';
 


constructor(private http: HttpClient,
private candidateservice: ServicesService){}



ngOnInit(): void {
  this.getLiveDemoList();
}



  private getLiveDemoList(){
  
    this.candidateservice.getLiveDemo().subscribe
    (
      data =>{
        this.demos = data;
        console.log(data)
        
      })
}
  


  deleteCandidate(id: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this candidate!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.http.delete(`/api/${id}`).subscribe(() => {
          this.getLiveDemoList();
        });
        Swal.fire('Deleted!', 'The candidate has been deleted.', 'success');
      }
    });
  }


//Pagination Links 
getPages(): number[] {
const pages: number[] = [];
const totalPages = this.getTotalPages();
for(let i=1; i<=totalPages; i++) {
  pages.push(i);
}
return pages;
}

getTotalPages(): number {
return Math.ceil(this.demos.length / this.itemsPerPage);
}

getPaginatedCandidates(): any[] {
const startIndex = (this.currentPage - 1) * this.itemsPerPage;
const endIndex = startIndex + this.itemsPerPage;
return this.demos.slice(startIndex, endIndex);
}






}
