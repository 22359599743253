



<div class=" col-md-12 flex-box row">

  <div class=" col-md-3  padd-top">
    <mat-card >
      <mat-card-header >
          <div class="icon-wrapper box">
              <mat-icon >person</mat-icon>
              <mat-card-title>Subscribers</mat-card-title>
           </div>
      </mat-card-header>
     
      <mat-card-content>
        <B style="color: rgb(28, 48, 228);"> Total-Subscribers: {{ count }}</B>
      </mat-card-content>
      <mat-card-actions>
        
      </mat-card-actions>
      
    </mat-card>


  </div>
  <div class=" col-md-3  padd-top">
    <mat-card >
      <mat-card-header>
          <div class="icon-wrapper box">
              <mat-icon >person</mat-icon>
              <mat-card-title>Active-Subscribers</mat-card-title>
           </div>
      </mat-card-header>
      <mat-card-content>
    
      </mat-card-content>
      <mat-card-actions>
        
      </mat-card-actions>
      
    </mat-card>


  </div>
  <div class=" col-md-3  padd-top">
    <mat-card >
      <mat-card-header>
          <div class="icon-wrapper box">
              <mat-icon >person</mat-icon>
              <mat-card-title>InActive-Subscribers</mat-card-title>
           </div>
      </mat-card-header>
      <mat-card-content>
    
      </mat-card-content>
      <mat-card-actions>
        
      </mat-card-actions>
      
    </mat-card>


  </div>
</div>