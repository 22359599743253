
<div class="menu-container">
  <cdk-accordion class="example-accordion">
    <ng-container *ngFor="let menuDay of menuData; let dayIndex = index;">
      <cdk-accordion-item
        [class.active]="expandedIndex === dayIndex"
        (click)="toggleAccordion(dayIndex)"
      >
        <div class="example-accordion-item-header">
          {{ menuDay.day }}
          <span class="example-accordion-item-description">
            Click to {{ expandedIndex === dayIndex ? 'close' : 'open' }}
          </span>
        </div>
        <div
          class="example-accordion-item-body"
          role="region"
          [style.display]="expandedIndex === dayIndex ? '' : 'none'"
        >
        <div class="menu-item" *ngFor="let menuItem of menuDay.menuItemsList">
          <div class="session">{{ menuItem.session }}</div>
          <div class="timing">{{ menuItem.timing }}</div>
          <span class="meal-name">{{ menuItem.itemName }}</span>
          <img [src]="getSafeImage(menuItem.itemImage)" alt="Menu Item Image" class="meal-image">
        </div>
        
        </div>
      </cdk-accordion-item>
    </ng-container>
  </cdk-accordion>
</div>
