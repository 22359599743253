<div class="container">
  <a href="javascript:history.back()" class="go-back-button">Go Back</a>

</div>

<div class="candidate-details" *ngIf="candidate">
  <div class="details">
    <h2>{{ candidate.fullName }}</h2>
    <p class="detail">Room Number: {{ candidate.numberInput }}</p>
    <p class="detail">Phone Number: {{ candidate.phoneNumber }}</p>
    <p class="detail">Advance Paid: {{ candidate.advance }}</p>
    <p class="detail">MonthlyFee: {{ candidate.fee }}</p>
    <p class="detail">Sharing: {{ candidate.sharing }}</p>
    <p class="detail">JoinDate:{{ candidate.date | date: 'MMM d, y' }}</p>
    <p class="balance">Due Amount: {{ candidate.balance }}</p>

    <div>
      <button (click)="sendReminder()" class="whatsapp-button">
        <i class="fab fa-whatsapp"></i>
        Send Reminder
      </button>
    </div>
    
    
    
  </div>
  
  <div class="photo-container">
    <img *ngIf="photo" [src]="getBase64Image(photo)" alt="Candidate Photo" class="photo" loading="lazy">
  </div>
</div>

<div class="payment-form-container">
  <h2 class="payment-form-heading">Admin Payment Portal</h2>
  <p class="payment-form-subheading">Make payments here</p>
  <div *ngIf="isLoading" class="loading-overlay">
    <div class="spinner"></div>
    <div class="loading-text">Please wait while Transaction</div>
  </div>
<form [formGroup]="paymentForm" class="payment-form" (ngSubmit)="submitPayment()">
  <label for="paymentAmount" class="payment-label">Payment Amount:</label>
  <input type="number" id="paymentAmount" formControlName="paymentAmount" class="payment-input">
  <br>

  <div class="input-box">
    <span class="details">paymentMonth</span>
   
    <mat-select formControlName="paymentMonth" placeholder="Select paymentMonth">
      <mat-option value="" disabled selected hidden class="white-text">Select paymentMonth</mat-option>
      <mat-option *ngFor="let month of months" [value]="month.value">
        {{ month.name }}
      </mat-option>
    </mat-select>
    
  </div>
  <br>
 
    <label class="payment-label">Payment Method:</label>
    <select formControlName="paymentMethod" class="payment-input" (change)="toggleTransactionIdField()">
      <option value="CASH">Cash</option>
      <option value="UPI">UPI</option>
    </select><br>
  
    <label *ngIf="isUPISelected" for="transactionId" class="payment-label">Transaction ID:</label>
    <input *ngIf="isUPISelected" type="text" id="transactionId" formControlName="transactionId" class="payment-input"
           placeholder="transactionId">
    <br>

  <div class="button" style="text-align: center; margin: 20px;">
    <button type="submit" [disabled]="isLoading" style="padding: 10px 20px; background-color: black; color: white; border: none; border-radius: 5px; cursor: pointer;">
      {{ isLoading ? 'Please wait while Transaction' : 'PAY' }}
    </button>
  </div>
</form>
</div>