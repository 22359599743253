import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AddCandidate } from '../addcandidate/addcandidate';

// interface Candidate {
//   id: number;
//   fullName: string;
//   phoneNumber: string;
//   numberInput: number;
// }

@Component({
  selector: 'app-search-candidates',
  templateUrl: './search-candidates.component.html',
  styleUrls: ['./search-candidates.component.css']
})
export class SearchCandidatesComponent implements OnInit {
  searchText: string = ''; 
  currentPage: number = 1;
  itemsPerPage: number = 8;
  selectedMonth!: number;
  selectedYear!: number;
  businessId: string = '';  // Add a property to store businessId

  candidatesWithPaidFees!: AddCandidate[];
  notPaidCandidates!: AddCandidate[];
  
 
  
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.searchCandidates();
    // Optionally, you can set a default month here if needed
    
  }

  // searchCandidates(): void {
  //   if (!this.selectedMonth || !this.businessId) {
  //     console.error('Please enter valid month and businessId.');
  //     return;
  //   }

  searchCandidates(): void {
    if (!this.selectedMonth || !this.selectedYear || !this.businessId) {
      console.error('Please enter valid month, year, and businessId.');
      return;
    }
 // Use the updated method with formatted month and year
 this.searchCandidates1();

    this.getCandidatesByPaidFees(this.businessId, this.selectedYear,this.selectedMonth);
    this.getCandidatesNotPaidForMonth(this.businessId, this.selectedYear,this.selectedMonth);
  }

  getCandidatesByPaidFees(businessId: string, paymentYear: number,paymentMonth: number): void {
    this.http.get<AddCandidate[]>(`https://api.careerneststay.in/api/candidates/paid-fees/${businessId}/${paymentYear}/${paymentMonth}`).subscribe(
      response => {
        this.candidatesWithPaidFees = response;
      },
      error => {
        console.error('Error fetching candidates with paid fees:', error);
      }
    );
  }

 
  

  getCandidatesNotPaidForMonth(businessId: string, paymentYear: number, month: number): void {
    this.http.get<AddCandidate[]>(`https://api.careerneststay.in/api/candidates/not-paid/${businessId}/${paymentYear}/${month}`).subscribe(
      response => {
        this.notPaidCandidates = response;
      },
      
      error => {
        console.error('Error fetching candidates not paid:', error);
      }
    );
  }

 
  calculateTotalFeesForMonth(month: number): number {
    let totalFees = 0;
  
    if (this.candidatesWithPaidFees) {
      for (const candidate of this.candidatesWithPaidFees) {
        console.log('Candidate:', candidate);
        if (candidate.fee && candidate.month === month) {
          totalFees += candidate.fee;
        }
      }
      
    }
  
    console.log('candidatesWithPaidFees:', this.candidatesWithPaidFees);
    console.log('month:', month);
    console.log('totalFees:', totalFees);
  
    return totalFees;
  }
  
  
  
  

  
  //Pagination Links 
getPages(): number[] {
  const pages: number[] = [];
  const totalPages = this.getTotalPages();
  for(let i=1; i<=totalPages; i++) {
    pages.push(i);
  }
  return pages;
}

getTotalPages(): number {
  return Math.ceil(this.candidatesWithPaidFees.length / this.itemsPerPage);
}

getPaginatedCandidates(): any[] {
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = startIndex + this.itemsPerPage;
  return this.candidatesWithPaidFees.slice(startIndex, endIndex);
}




// Updated method to include year
searchCandidates1(): void {
  const formattedMonthYear = this.formatMonthYear(this.selectedMonth, this.selectedYear);

  this.http.get<AddCandidate[]>(`https://api.careerneststay.in/api/candidates/paid-fees/${this.businessId}/${formattedMonthYear}`).subscribe(
    response => {
      this.candidatesWithPaidFees = response;
    },
    error => {
      console.error('Error fetching candidates with paid fees:', error);
    }
  );
}

// New method to format month and year into a single string
formatMonthYear(month: number, year: number): string {
  return `${year}-${month.toString().padStart(2, '0')}`;
}


}
