<div class="container">
<h2>Update Menu Item</h2>
<form [formGroup]="updateForm" (ngSubmit)="updateMenuItem()">
  <div>
    <label for="itemName">Item Name:</label>
    <input type="text" id="itemName" name="itemName" formControlName="itemName">
  </div>
  <div>
    <label for="timing">Timing:</label>
    <input type="text" id="timing" name="timing" formControlName="timing">
  </div>
  <div>
    <label for="session">Session:</label>
    <input type="text" id="session" name="session" formControlName="session">
  </div>
  <div>
    <label for="itemImage">Item Image:</label>
    <input type="file" id="itemImage" name="itemImage" (change)="onFileSelected($event)">
  </div>
  <div>
    <img [src]="getSafeImage(menuItemData.itemImage)" alt="Menu Item Image" class="photo">
  </div>
  <div>
    <button mat-dialog-close>Cancel</button>
    <button type="submit" [disabled]="updateForm.invalid">Update</button>
  </div>
</form>
</div>