import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { AddCandidate } from '../addcandidate/addcandidate';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';
import { UpdatecandidateComponent } from '../updatecandidate/updatecandidate.component';
declare const annyang: any;

interface PaymentDetail {
  id: number;
  paymentMonth: number;
  paid: boolean;
}

@Component({
  selector: 'app-listcandidates',
  templateUrl: './listcandidates.component.html',
  styleUrls: ['./listcandidates.component.css']
})

export class ListcandidatesComponent {
  searchText: string = ''; 
    candidates: AddCandidate[] = [];
    currentPage: number = 1;
    itemsPerPage: number = 8;
    employeeId:any;
    interviewerName:any;
    businessId: string = '';
    updateCandidateData: any;
    loading: boolean = false;


constructor(private dialog: MatDialog,
  private sanitizer: DomSanitizer,private http: HttpClient,
  private route: ActivatedRoute,
  private candidateservice: ServicesService,
  private router:Router,
   private cdr: ChangeDetectorRef, private ngZone: NgZone ){}
 


  ngOnInit(): void {
    this.getCandidate();
}

    private getCandidate(){
      this.loading = true;
      let businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
      this.candidateservice.getCandidateList(businessId1).subscribe
      (
        data =>{
          this.candidates = data;
         // console.log(data)
          this.loading = false; 
        },
        (error) => {
          console.error(error);
          this.loading = false;
        }
        )
}
    }

    
    viewCandidateDetails(id: string) {
      const currentBusinessId = 'your-business-id'; // Replace with the actual business ID
      this.router.navigate(['client-adminhome', currentBusinessId, 'candidates', id]);
    }

    getpaymentHistory(candidateId: string) {
      const currentBusinessId = 'your-business-id'; 
      this.router.navigate(['client-adminhome', currentBusinessId, 'getpayment', candidateId]);
    }

    // viewCandidateDetails(id: string) {
    //   const currentBusinessId = this.businessId; // Use the dynamically set businessId
    //   this.router.navigate(['client-adminhome', currentBusinessId, 'candidates', id]);
    // }


    deleteCandidate(id: number): void {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this candidate!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel'
      }).then((result) => {
        if (result.isConfirmed) {
          this.http.delete(`https://api.careerneststay.in/api/deletecandi/${id}`).subscribe(() => {
           
          });
          Swal.fire('Deleted!', 'The candidate has been deleted.', 'success');
           // Refresh the candidate list after deletion
           // Remove the deleted candidate from the local array
      this.candidates = this.candidates.filter(c => c.id !== id);
      this.getCandidate();

        }
      });
    }
    
handleBackupData(backupData: any): void {
  // Implement the logic to handle backup data
  // For example, save it to a backup table, a file, or any other storage mechanism.
  console.log('Backup Data:', backupData);
}


updateCandidate(id: number): void {
  const stringId: string = id.toString(); // Convert id to string
  this.candidateservice.getCandidateDetailsById(stringId).subscribe(candidate => {
    const dialogRef = this.dialog.open(UpdatecandidateComponent, {
      width: '1200px',
      height: 'auto',
      data: { ...candidate }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateCandidateData(result);
      }
    });
  });
}


//Pagination Links 
getPages(): number[] {
  const pages: number[] = [];
  const totalPages = this.getTotalPages();
  for(let i=1; i<=totalPages; i++) {
    pages.push(i);
  }
  return pages;
}

getTotalPages(): number {
  return Math.ceil(this.candidates.length / this.itemsPerPage);
}

// Updated pagination logic
  getPaginatedCandidates(): AddCandidate[] {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;

    // Filter candidates based on search text
    const filteredCandidates = this.candidates.filter(candidate => {
      const searchTextLowerCase = this.searchText.toLowerCase();
      return (
        (candidate.city && candidate.city.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.phoneNumber && candidate.phoneNumber.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.address && candidate.address.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.aadharNumber && candidate.aadharNumber.includes(searchTextLowerCase)) ||
        (candidate.fullName && candidate.fullName.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.numberInput && candidate.numberInput.toString().includes(searchTextLowerCase)) ||
        (candidate.sharing && candidate.sharing.toString().includes(searchTextLowerCase))
      );
    });

    // Return paginated results
    return filteredCandidates.slice(startIndex, endIndex);
  }


  voiceActiveSectionDisabled: boolean = true;
  voiceActiveSectionError: boolean = false;
  voiceActiveSectionSuccess: boolean = false;
  voiceActiveSectionListening: boolean = false;
  voiceText: any;
  
  
  
  
  initializeVoiceRecognitionCallback(): void {
   annyang.addCallback('error', (err: { error: string; }) => {
       if(err.error === 'network'){
         this.voiceText = "Internet is require";
         console.log(annyang);
  
         annyang.abort();
         this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
       } else if (this.voiceText === undefined) {
     this.ngZone.run(() => this.voiceActiveSectionError = true);
     annyang.abort();
    }
   });
  
     annyang.addCallback('soundstart', (res: any) => {
       // Use the 'res' parameter if needed
       console.log('Sound started:', res);
       this.ngZone.run(() => this.voiceActiveSectionListening = true);
     });
     
   annyang.addCallback('end', () => {
       if (this.voiceText === undefined) {
         this.ngZone.run(() => this.voiceActiveSectionError = true);
     annyang.abort();
    }
   });
  
     annyang.addCallback('result', (userSaid: any[], res: any) => {
       this.ngZone.run(() => this.voiceActiveSectionError = false);
     
    let queryText: any = userSaid[0];
  
    annyang.abort();
  
       this.voiceText = queryText;
  
    this.ngZone.run(() => this.voiceActiveSectionListening = false);
       this.ngZone.run(() => this.voiceActiveSectionSuccess = true);
   });
  }
  
   startVoiceRecognition(): void {
     this.voiceActiveSectionDisabled = false;
     this.voiceActiveSectionError = false;
     this.voiceActiveSectionSuccess = false;
     this.voiceText = undefined;
   
     if (typeof annyang !== 'undefined') {
       let commands = {
         'demo-annyang': () => { }
       };
   
       annyang.addCommands(commands);
   
       this.initializeVoiceRecognitionCallback();
   
       annyang.start({ autoRestart: false });
     } else {
       console.error('Annyang is not defined. Ensure the script is loaded.');
     }
   }
   
  closeVoiceRecognition(): void {
     this.voiceActiveSectionDisabled = true;
   this.voiceActiveSectionError = false;
   this.voiceActiveSectionSuccess = false;
   this.voiceActiveSectionListening = false;
   this.voiceText = undefined;
  
   if(annyang){
       annyang.abort();
     }
  }
  


getCandidateCount(): void {
  if (this.businessId) {
    this.http.get<any>(`https://api.careerneststay.in/api/countCandidates/${this.businessId}`).subscribe(
      (candidateCount) => {
        // Display the count in a modal
        this.showCountModal(candidateCount);
      },
      (error) => {
        console.error('Error fetching candidate count', error);
      }
    );
  } else {
    // Handle the case where businessId is empty or not provided
    console.error('Business ID is empty or not provided');
  }
}



 // Function to display the candidate count in a modal
 showCountModal(candidateCount: number): void {
  // You can use a library like MatDialog to open a modal dialog to display the count
  // Example using MatDialog:
  const dialogRef = this.dialog.open(CountModalComponent, {
    data: { candidateCount: candidateCount },
  });

  // You can handle the dialog result here if needed
  dialogRef.afterClosed().subscribe((result) => {
    console.log('Dialog closed', result);
  });
}

}



@Component({
  selector: 'count-modal',
  template: `
    <div class="modal-container">
      <h2 class="modal-title">Candidate Count</h2>
      <p class="candidate-count">Total candidates: <span class="large-text">{{ data.candidateCount }}</span></p>
      <button mat-button class="close-button" (click)="closeDialog()">Close</button>
    </div>
  `,
  styles: [`
    .modal-container {
      padding: 20px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
    }

    .modal-title {
      font-size: 24px;
      margin-bottom: 10px;
    }

    .candidate-count {
      font-size: 18px;
    }

    .close-button {
      margin-top: 20px;
    },
    p .large-text {
    font-size: 30px; 
}

  `],
})
export class CountModalComponent {
  constructor(public dialogRef: MatDialogRef<CountModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  closeDialog(): void {
    this.dialogRef.close();
  }







  
}


