import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from '../services.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-updatecandidate',
  templateUrl: './updatecandidate.component.html',
  styleUrls: ['./updatecandidate.component.css']
})
export class UpdatecandidateComponent {
  candidateForm!: FormGroup ;
  selectedFile!: File;
  phoneNumbersValidators: any;
  alternateNumber! : number;
  phoneNumber !: number;
  show! : boolean;
  isLoading = false;



  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private candidateservice: ServicesService,
    private dialogRef: MatDialogRef<UpdatecandidateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
    
  ngOnInit(): void {
    // Log the received data for debugging
    console.log('Received data:', this.data);
  
    // Initialize the form and populate fields with data
    this.candidateForm = this.formBuilder.group({
      fullName: [this.data.fullName ?? '', [Validators.required]],
      fatherName: [this.data.fatherName ?? '', [Validators.required]],
      phoneNumber: [this.data.phoneNumber ?? '', [Validators.required]],
      aadharNumber: [this.data.aadharNumber ?? '', [Validators.required]],
      address: [this.data.address ?? '', Validators.required],
      city: [this.data.city ?? ''],
      gender: [this.data.gender ?? ''],
      maritalStatus: [this.data.maritalStatus ?? ''],
      file: [this.data.file ?? ''], 
      numberInput: [this.data.numberInput ?? '', [Validators.required]],
      sharing: [this.data.sharing ?? '', Validators.required],
      date: [this.data.date ?? '', Validators.required],
      advance: [this.data.advance ?? '', [Validators.required]],
      fee: [this.data.fee ?? '', [Validators.required]],
      email: [this.data.email ?? '', [Validators.required]]
    });
  
    // Log the form values for debugging
    console.log('Form values:', this.candidateForm.value);
  }
  


  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onSubmit(id: number) {
  
    this.isLoading = true;
    setTimeout(() => {

    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('address', this.candidateForm.get('address')?.getRawValue());
    formData.append('date', this.candidateForm.get('date')?.getRawValue());
    formData.append('fullName', this.candidateForm.get('fullName')?.getRawValue());
    formData.append('fatherName', this.candidateForm.get('fatherName')?.getRawValue());
    formData.append('phoneNumber', this.candidateForm.get('phoneNumber')?.getRawValue());
    formData.append('city', this.candidateForm.get('city')?.getRawValue());
    formData.append('aadharNumber', this.candidateForm.get('aadharNumber')?.getRawValue());
    formData.append('maritalStatus', this.candidateForm.get('maritalStatus')?.getRawValue());
    formData.append('numberInput', this.candidateForm.get('numberInput')?.getRawValue());
    formData.append('advance', this.candidateForm.get('advance')?.getRawValue());
    formData.append('fee', this.candidateForm.get('fee')?.getRawValue());
    formData.append('gender', this.candidateForm.get('gender')?.getRawValue());
    formData.append('sharing', this.candidateForm.get('sharing')?.getRawValue());
    formData.append('email', this.candidateForm.get('email')?.getRawValue());

   
        this.candidateservice.UpdateCandidate(id,formData).subscribe(
          (response) => {
            console.log(response);
            Swal.fire({
              title: 'Data updated successfully!',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            }).then(() => {
              this.candidateForm.reset();
              this.isLoading = false;
            });
          },
          (error) => {
            console.error(error);
            // Handle error if necessary
            // Reset isLoading to false in case of error
            this.isLoading = false;
          }
        );
    
    }, 2000);
  }
}
