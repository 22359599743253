<!-- backupcandidates.component.html -->

<div class="container mt-4">
    <h2>Deleted Candidates</h2>
    <input type="text" class="form-control mb-2" placeholder="Search..." [(ngModel)]="searchText" (input)="filterBackups()">
  
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Full Name</th>
          <th>Phone Number</th>
          <th>Email</th>
        <th>Joindate</th>
        <th>Fee</th>
        <th>Sharing</th>
        <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let backup of filteredBackups">
          <td>{{ backup.fullName }}</td>
          <td>{{ backup.phoneNumber }}</td>
          <td>{{ backup.email }}</td>
          <td>{{backup.date}}</td>
          <td>{{backup.fee}}</td>
          <td>{{backup.sharing}}</td>
          <td>
            <button class="btn btn-danger" (click)="deletebackCandidate(backup.id)">Delete Permanently</button>
          </td>
                </tr>
      </tbody>
    </table>
  </div>
  