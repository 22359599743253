import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { ChangepasswordComponent } from '../changepassword/changepassword.component';
import { HttpClient } from '@angular/common/http';
import { AddbankaccountComponent } from '../addbankaccount/addbankaccount.component';

@Component({
  selector: 'app-client-admin-home',
  templateUrl: './client-admin-home.component.html',
  styleUrls: ['./client-admin-home.component.css']
})
export class ClientAdminHomeComponent {
  totalCandidates: number = 0; // Set this value based on your data
  totalCollections: number = 0;
  isShowSkipButton: boolean = false;
  user: any;
  showNotification!: boolean;
  businessId!: string | null;
  dayId!: number;
  updateCandidateData: any;

  constructor(private router: Router,private dialog: MatDialog,
    private http: HttpClient) {}
  
ngOnInit(){
  this.businessId = localStorage.getItem('businessId');
    this.user = this.businessId ? this.businessId : ''; 
    
    // Call getCandidateCount on component initialization
   // this.getCandidateCount();
}

 navigateToDashboard(): void {
    this.router.navigate(['/dashboard']);
  }

dismissNotification(): void {
  this.showNotification = false;
}

logout() {
  Swal.fire({
    title: 'Are you sure you want to log out?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, log out',
    cancelButtonText: 'Cancel',
  }).then((result) => {
    if (result.isConfirmed) {
      // User confirmed the logout
      localStorage.clear(); // Clear localStorage
      this.router.navigate(['']); // Navigate to the root URL or login page
    }
  });
}

openPopUp() {
  this.dialog.open(ChangepasswordComponent, {
    width: '400px',
    height: '450px',
  });
}

openBank() {
  this.dialog.open(AddbankaccountComponent, {
    width: '400px',
    height: '450px',
  });
}

dataSaved(): void {
  this.showNotification = true;

  // Close the notification after a certain duration
  setTimeout(() => {
    this.showNotification = false;
  }, 3000);
}



}
