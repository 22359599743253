import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-my-money',
  templateUrl: './my-money.component.html',
  styleUrls: ['./my-money.component.css']
})
export class MyMoneyComponent implements OnInit {
  businessId: string = '';
  totalFees!: number;
  feesSummary: { totalPaidFees: number, totalDues: number } = { totalPaidFees: 0, totalDues: 0 };
  selectedMonth: number = new Date().getMonth() + 1;
  selectedYear: number = new Date().getFullYear();

  constructor(private http: HttpClient) {}

  ngOnInit(): void {}

  getTotalFees() {
    if (!this.businessId) {
      console.error('businessId is not defined.');
      return;
    }

    const backendUrl = `https://api.careerneststay.in/api/candidates/total-fees/${this.businessId}`;

    this.http.get<number>(backendUrl).subscribe(
      (data) => {
        this.totalFees = data;
        this.getFeesSummary();
      },
      (error) => {
        console.error('Error fetching total fees:', error);
      }
    );
  }

  getFeesSummary() {
    if (!this.businessId) {
      console.error('businessId is not defined.');
      return;
    }

    const feesSummaryUrl = `https://api.careerneststay.in/api/candidates/fees-summary/${this.businessId}/${this.selectedYear}/${this.selectedMonth}`;

    this.http.get<any>(feesSummaryUrl).subscribe(
      (data) => {
        this.feesSummary = data;
      },
      (error) => {
        console.error('Error fetching fees summary:', error);
      }
    );
  }
}
