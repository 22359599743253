<body style="background-image: url('../../assets/room.jpg'); background-size: cover; background-position: center; height: 100vh; margin: 0; display: flex; align-items: center; justify-content: center;">

  <form [formGroup]="loginForm" (ngSubmit)="login()" class="login-form">
    <div class="form-group">
      <label for="businessId">Business ID:</label>
      <input type="text" id="businessId" class="form-control" formControlName="businessId" required>
    </div>
    
    <div class="form-group">
      <label for="password">Password:</label>
      <div class="password-input">
        <input type="{{ showPassword ? 'text' : 'password' }}" id="password" class="form-control" formControlName="password" required minlength="3">
        <i class="fa fa-eye" aria-hidden="true" (click)="togglePasswordVisibility()"></i>
      </div>
    </div>

    <button type="submit" class="btn btn-primary">Login</button>

    <div class="forgot-password">
      <a href="#" (click)="forgotPassword()">Forgot Password?</a>
    </div>
  </form>
</body>
