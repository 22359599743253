import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from '../services.service';
import { Business } from 'src/app/table/business';
import { AbstractControl, ValidatorFn } from '@angular/forms';


interface Room {
  id: number; 
  roomType: number;
  monthlyRent: number;
  contactNumber: string;
  bedsAvailable:string;
  roomNumber: string;
}
@Component({
  selector: 'app-addcandidate',
  templateUrl: './addcandidate.component.html',
  styleUrls: ['./addcandidate.component.css']
})
export class AddcandidateComponent {
  //candidateForm!: FormGroup ;
  candidateForm: any;
  selectedFile!: File;
  phoneNumbersValidators: any;
  alternateNumber! : number;
  phoneNumber !: number;
  show! : boolean;
  isLoading = false;
  businessId!: string; 
  business: any[] = [];
  roomNumbers: Room[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private candidateservice: ServicesService
  ) {}
    
  ngOnInit(): void {
    this.candidateForm = this.formBuilder.group({
      fullName: [null, [Validators.required]],
      fatherName: [null, [Validators.required]],
      phoneNumber: [null, [Validators.required, Validators.pattern(/^\d+$/)]],
      aadharNumber: [null, [Validators.required]],
      address: [null, Validators.required],
      city: [null],
      gender: [''],
      maritalStatus: [''],
      file: [''], 
      numberInput: ['', [Validators.required]],
      sharing: ['', Validators.required],
      date: ['', [Validators.required, futureDateValidator()]],
      advance: [null, [Validators.required]],
      fee: [null, [Validators.required]],
      email: ['', [Validators.required, Validators.email]],

      
    });

   // Load business data and room numbers on component initialization
   const businessId1 = localStorage.getItem("businessId");
   if (businessId1 != null) {
     this.businessId = businessId1;
     this.candidateservice.getRoomsData(businessId1).subscribe(
       (response: any[]) => {
         this.business = response;
         console.log('Business data:', response);
       },
       error => {
         console.error(error);
       }
     );
   }

   this.loadRoomNumbers();
 }

 // Function to load room numbers based on the selected room type
 loadRoomNumbers(): void {
   const selectedRoomType = this.candidateForm.get('sharing').value;
   const selectedBusiness = this.business.find(b => b.roomType === selectedRoomType);

   if (selectedBusiness) {
     this.roomNumbers = selectedBusiness.rooms;
   } else {
     this.roomNumbers = [];
   }
 }

 // Add this function to be called whenever the sharing select value changes
 onSharingChange(): void {
   this.loadRoomNumbers();
 }
    
    

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }
  
  onSubmit() {
    if (this.candidateForm.valid) {
    this.isLoading = true;
    setTimeout(() => {

    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('address', this.candidateForm.get('address')?.getRawValue());
    formData.append('date', this.candidateForm.get('date')?.getRawValue());
    formData.append('fullName', this.candidateForm.get('fullName')?.getRawValue());
    formData.append('fatherName', this.candidateForm.get('fatherName')?.getRawValue());
    formData.append('phoneNumber', this.candidateForm.get('phoneNumber')?.getRawValue());
    formData.append('city', this.candidateForm.get('city')?.getRawValue());
    formData.append('aadharNumber', this.candidateForm.get('aadharNumber')?.getRawValue());
    formData.append('maritalStatus', this.candidateForm.get('maritalStatus')?.getRawValue());
    formData.append('numberInput', this.candidateForm.get('numberInput')?.getRawValue());
    formData.append('advance', this.candidateForm.get('advance')?.getRawValue());
    formData.append('fee', this.candidateForm.get('fee')?.getRawValue());
    formData.append('gender', this.candidateForm.get('gender')?.getRawValue());
    formData.append('sharing', this.candidateForm.get('sharing')?.getRawValue());
    formData.append('email', this.candidateForm.get('email')?.getRawValue());

    let businessId1 = localStorage.getItem('businessId');
      if (businessId1 != null) {
        this.candidateservice.addCandidate(formData, businessId1).subscribe(
          (response) => {
            console.log(response);
            
            Swal.fire({
              title: 'Data saved successfully!',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            }).then(() => {
              this.candidateForm.reset();
              this.isLoading = false;
            });
          },
          (error) => {
            console.error(error);
            // Handle error if necessary
            // Reset isLoading to false in case of error
            this.isLoading = false;
          }
        );
      }
    }, 2000);
  }
  else {
    // Get the list of fields with validation errors
    const invalidFields = Object.keys(this.candidateForm.controls).filter(controlName => {
      const control = this.candidateForm.get(controlName);
      return control && control.invalid;
    });

    // Highlight missing fields
    invalidFields.forEach(fieldName => {
      const control = this.candidateForm.get(fieldName);
      if (control) {
        control.markAsTouched();
        control.markAsDirty();
      }
    });

    // Highlight specific fields for Sharing Type and Room Number
    if (invalidFields.includes('sharing')) {
      this.candidateForm.get('sharing')?.markAsTouched();
      this.candidateForm.get('sharing')?.markAsDirty();
    }

    if (invalidFields.includes('numberInput')) {
      this.candidateForm.get('numberInput')?.markAsTouched();
      this.candidateForm.get('numberInput')?.markAsDirty();
    }

    // SweetAlert for invalid form submission
    Swal.fire({
      title: 'Error',
      html: `Please fill in all required fields before submitting.<br>Missing fields: ${invalidFields.join(', ')}`,
      icon: 'error',
    });
  }

}
}





  export function futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedDate = new Date(control.value);
      const currentDate = new Date();
  
      if (selectedDate > currentDate) {
        return { 'futureDate': true };
      }
  
      return null;
    };
  }


    
  


