import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ServicesService } from '../Clients/services.service';
import Swal from 'sweetalert2';
declare const Razorpay: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class IndexComponent implements OnInit {
  liveDemoForm!: FormGroup;
  isLoading = false;
  businessId: string = '';
  amount: number = 0;
  rzp: any;
  orderId!: string;
  razorpayScriptLoaded = false;

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private candidateService: ServicesService
  ) {}

  ngOnInit(): void {
    this.liveDemoForm = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
      comments: ['', Validators.required],
      organization: ['', Validators.required],
      residents: ['', Validators.required],
      areaLocality: ['', Validators.required],
      demoDateTime: ['', Validators.required],
    });

      // Call the function to initialize Razorpay only if the script is loaded
  if (this.razorpayScriptLoaded) {
    this.initializeRazorpay();
  } else {
    this.loadRazorpayScript().then(() => {
      this.razorpayScriptLoaded = true;
      this.initializeRazorpay();
    });
  }
  }

  subscribePlan(planAmount: number): void {
    const minimumAmount = 0.01;
    this.amount = Math.max(planAmount, minimumAmount);
  
    // Always initialize a new Razorpay instance when the amount changes
    this.loadRazorpayScript().then(() => {
      this.razorpayScriptLoaded = true;
      this.initializeRazorpay();
    });
  }
  
  
  initializeRazorpay(): void {
    if (this.razorpayScriptLoaded) {
      const options = {
        key: 'rzp_test_KdiESTDcHPC74B',
        amount: this.amount * 100, // Multiply by 100 to convert to paise
        currency: 'INR',
        name: 'unsofttech',
        description: 'Payment for your product or service',
        order_id: this.orderId,
        handler: this.paymentHandler.bind(this),
        prefill: {
          name: 'John Doe',
          email: 'john@example.com',
          contact: '1234567890'
        },
        notes: {
          address: 'Your Address'
        },
        theme: {
          color: '#F37254'
        }
      };
  
      // Create a new Razorpay instance
      this.rzp = new (window as any).Razorpay(options);
    }
  }
  
  

  paymentHandler(response: any): void {
    // Handle the payment response here (you can send it to your backend for verification)
    console.log(response);
  
    // Display a success message using SweetAlert2
    Swal.fire({
      icon: 'success',
      title: 'Payment Successful!',
      text: 'Thank you for your payment.',
      showConfirmButton: false,
      timer: 2000 // Automatically close after 2 seconds
    });
  
    // Make an HTTP request to save payment details
    // this.candidateService.savePaymentDetails(response, this.businessId)
    //   .subscribe(
    //     (result) => {
    //       console.log('Payment details saved successfully:', result);
    //     },
    //     (error) => {
    //       console.error('Failed to save payment details:', error);
    //     }
    //   );
  }
  

  openRazorpay(): void {
    if (this.rzp) {
      console.log('Opening Razorpay with amount:', this.amount);
      this.rzp.open();
    } else {
      console.error('Razorpay instance not initialized.');
    }
  }
  
  

  loadRazorpayScript(): Promise<void> {
    return new Promise<void>((resolve) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve();
      document.head.appendChild(script);
    });
  }









  onSubmit() {
    
    if (this.liveDemoForm.valid) {
     
      const formData: FormData = new FormData();
      // Append form values to FormData
      formData.append('name', this.liveDemoForm.get('name')!.value);
      formData.append('email', this.liveDemoForm.get('email')!.value);
      formData.append('phoneNumber', this.liveDemoForm.get('phoneNumber')!.value);
      formData.append('comments', this.liveDemoForm.get('comments')!.value);
      formData.append('organization', this.liveDemoForm.get('organization')!.value);
      formData.append('residents', this.liveDemoForm.get('residents')!.value);
      formData.append('areaLocality', this.liveDemoForm.get('areaLocality')!.value);
      formData.append('demoDateTime', this.liveDemoForm.get('demoDateTime')!.value);
  
  
  
      this.candidateService.addLiveDemo(formData).subscribe(
        (response) => {
          console.log(response);
          Swal.fire({
            title: 'Data saved successfully! We will get back to you soon',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          }).then(() => {
            // Reset the correct form instance
            this.liveDemoForm.reset();
           
          });
        },
        (error) => {
          console.error(error);
         
        }
      );
  
      console.log('Form submitted:', this.liveDemoForm.value);
    } else {
      // Form is invalid, show error messages or perform custom logic
      console.log('Form is invalid. Please check the fields.');
    }
  }
  

  navigateLogin(){
    this.route.navigate(['/login']);
  }






}
