import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from '../services.service';
import { MatDialog } from '@angular/material/dialog';
import { AddMenuComponent } from '../add-menu/add-menu.component';




// days.model.ts
export interface Days {
  dayId: number;
  day: string;
  // Other properties as needed
}


@Component({
  selector: 'app-add-day',
  templateUrl: './add-day.component.html',
  styleUrls: ['./add-day.component.css']
})
export class AddDayComponent {
  selectedFile!: File;
  DayForm!: FormGroup ;
  searchText: string = ''; 
  currentPage: number = 1;
  itemsPerPage: number = 8;
  businessId: string = '';
  days: Days[] = [];
 
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private candidateservice: ServicesService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {}
    
  ngOnInit(): void {
    this.DayForm = this.formBuilder.group({
      day: [null,[Validators.required]],
      meal: [null, [Validators.required]],
      timing: [null, [Validators.required]],
      image: [''],
      businessId: [null]  
    });

    this.getDay();
  }
  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }
  // onSubmit() {
  //   const formData: FormData = new FormData();
  //   // formData.append('image', this.selectedFile);
  //   formData.append('day', this.DayForm.get('day')?.value);
  
  //   let businessId1 = localStorage.getItem("businessId");
  //   if (businessId1 != null) {
  //   this.candidateservice.addDay(formData, businessId1).subscribe(
  //     response => {
  //       console.log(response);
  //       Swal.fire({
  //         title: 'Data saved successfully!',
  //         icon: 'success',
  //         timer: 3000,
  //         showConfirmButton: false
  //       }).then(() => {
  //         this.DayForm.reset();
  //       });
  //     }
  //   );
  //   }
  // }


 
  
  onSubmit() {
    const formData: FormData = new FormData();
    formData.append('day', this.DayForm.get('day')?.value);
  
    let businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
      this.candidateservice.addDay(formData, businessId1).subscribe(
        (response: any) => {
          console.log(response);
          if (response instanceof HttpErrorResponse && response.status === 409) {
            // Day already exists, show a SweetAlert
            Swal.fire({
              title: 'Day Already Exists!',
              text: 'The day you are trying to save already exists.',
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Data saved successfully!',
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            }).then(() => {
              this.DayForm.reset();
            });
          }
        },
        (error: HttpErrorResponse) => {
          // Handle the error, display an error message, etc.
          console.error('Error:', error);
          if (error.status === 409) {
            Swal.fire({
              title: 'Day Already Exists!',
              text: 'The day you are trying to save already exists.',
              icon: 'error'
            });
          } else {
            Swal.fire({
              title: 'Error',
              text: 'An error occurred while saving the data.',
              icon: 'error'
            });
          }
        }
      );
    }
  }


  private getDay(){
    let businessId1 = localStorage.getItem("businessId");
  if (businessId1 != null) {
    this.candidateservice.getAllDaysByBusinessId(businessId1).subscribe
    (
      data =>{
        this.days = data;
        console.log(data)
      })}
  }




  addMenu(id: number) {
    console.log('DayId:', id);  // Check the value of id
    const dialogRef = this.dialog.open(AddMenuComponent, {
      width: '1200px',
      data: { id: id },  // Pass the dayId as an object
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  
  
  deleteDay(dayId: number): void {
    // Implement the delete functionality
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // User confirmed, proceed with deletion
        this.candidateservice.deleteDay(dayId).subscribe(
          () => {
            console.log('Day deleted successfully');
            // Show success message
            Swal.fire('Deleted!', 'The day has been deleted.', 'success');
            // Refresh the list of days after deletion
            this.getDay();
          },
          (error) => {
            console.error('Error deleting day:', error);
            // Show error message
            Swal.fire('Error!', 'An error occurred while deleting the day.', 'error');
          }
        );
      }
    });
  }
 


//Pagination Links 
getPages(): number[] {
const pages: number[] = [];
const totalPages = this.getTotalPages();
for(let i=1; i<=totalPages; i++) {
  pages.push(i);
}
return pages;
}

getTotalPages(): number {
return Math.ceil(this.days.length / this.itemsPerPage);
}

getPaginatedCandidates(): any[] {
const startIndex = (this.currentPage - 1) * this.itemsPerPage;
const endIndex = startIndex + this.itemsPerPage;
return this.days.slice(startIndex, endIndex);
}


}
