import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup ,Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from '../services.service';

// Custom Validator
export function roomTypeValidator(existingRoomTypes: number[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (existingRoomTypes.includes(value)) {
      return { duplicateRoomType: true };
    }
    return null;
  };
}


@Component({
  selector: 'app-add-fee',
  templateUrl: './add-fee.component.html',
  styleUrls: ['./add-fee.component.css']
})
export class AddFeeComponent {
  RoomForm!: FormGroup ;
  existingRoomTypes: number[] = []; 

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private candidateservice: ServicesService
  ) {}
    
  ngOnInit(): void {
    this.RoomForm = this.formBuilder.group({
      roomType: [null, [Validators.required, roomTypeValidator(this.existingRoomTypes)]],
      monthlyRent: [null, [Validators.required]],
      contactNumber: [null, [Validators.required]],
      bedsAvailable: [null, [Validators.required]],
      businessId: [null]  
    });


  }

  onSubmit() {
    const formData: FormData = new FormData();
    formData.append('roomType', this.RoomForm.get('roomType')?.value);
    formData.append('monthlyRent', this.RoomForm.get('monthlyRent')?.value);
    formData.append('contactNumber', this.RoomForm.get('contactNumber')?.value);
    formData.append('bedsAvailable', this.RoomForm.get('bedsAvailable')?.value);
  
    const businessId = localStorage.getItem("businessId");
  
    if (businessId) {
      this.candidateservice.addFee(formData, businessId).subscribe(
        response => {
         // console.log(response);
            Swal.fire({
              title: 'Fee Details saved successfully!',
              icon: 'success',
            }).then(() => {
              this.RoomForm.reset();
            });
          
        },
        error => {
          console.error('Error saving room details', error);
          if (error.status === 409) {
            Swal.fire({
              title: 'Duplicate room type!',
              icon: 'error',
              text: 'This Sharing already exists for this business.',
            });
          }
        }
      );
    }
  }
  
  

  
  
}
