import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppRoutingModule, routerOutlet } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HttpClientModule } from "@angular/common/http";
import { Routes, RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { UniquePipe } from "./unique.pipe";
import { MatDialogModule } from "@angular/material/dialog";
import { LoginComponent } from "./Cart/login/login.component";
import { MatCardModule } from "@angular/material/card";
import { MatPaginator, MatPaginatorModule } from "@angular/material/paginator";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatListModule } from "@angular/material/list";
import { MatMenuModule } from '@angular/material/menu';
import { TableComponent } from "./table/table.component";
import { MatTableModule } from "@angular/material/table";
import { CardComponent } from "./card/card.component";

import { MatProgressBarModule } from "@angular/material/progress-bar";

import { EditDetailsComponent } from "./edit-details/edit-details.component";

import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSortModule } from "@angular/material/sort";

import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";

import { HeaderComponent } from "./header/header.component";
import { RegistComponent } from "./regist/regist.component";
import { UserViewComponent } from "./user-view/user-view.component";
import { TableqrComponent } from "./tableqr/tableqr.component";
import { MatInputModule } from "@angular/material/input";
import { TablesearchPipe } from './table/tablesearch.pipe';
import { ChangepasswordComponent } from "./Clients/changepassword/changepassword.component";
import { ClientAdminHomeComponent } from "./Clients/client-admin-home/client-admin-home.component";
import { AddcandidateComponent } from './Clients/addcandidate/addcandidate.component';
import { AddFeeComponent } from './Clients/add-fee/add-fee.component';
import { AddMenuComponent } from './Clients/add-menu/add-menu.component';
import { ListfeeComponent } from './Clients/listfee/listfee.component';
import { ListmenuComponent } from './Clients/listmenu/listmenu.component';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { ListcandidatesComponent } from './Clients/listcandidates/listcandidates.component';
import { CandidateFilterPipePipe } from './Clients/candidate-filter-pipe.pipe';
import {MatSelectModule} from '@angular/material/select';
import { CandidateDetailComponent } from './Clients/candidate-detail/candidate-detail.component';
import { TermsandconditionsComponent } from './Clients/termsandconditions/termsandconditions.component';
import { GetpaymenthistoryComponent } from './Clients/getpaymenthistory/getpaymenthistory.component';
import { AdminlistfeeComponent } from './Clients/adminlistfee/adminlistfee.component';
import { UpdateroomComponent } from './Clients/updateroom/updateroom.component';
import { AddDayComponent } from './Clients/add-day/add-day.component';
import { SearchCandidatesComponent } from './Clients/search-candidates/search-candidates.component';
import { SendEmailsComponent } from './Clients/send-emails/send-emails.component';
import { AdminlistmenuComponent } from './Clients/adminlistmenu/adminlistmenu.component';
import { UpdatemenuitemsComponent } from './Clients/updatemenuitems/updatemenuitems.component';
import { IndexComponent } from './index/index.component';
import { LivedemoComponent } from './livedemo/livedemo.component';
import { MyMoneyComponent } from './Clients/my-money/my-money.component';
import { UpdatecandidateComponent } from './Clients/updatecandidate/updatecandidate.component';
import { SubscriptionComponent } from './Clients/subscription/subscription.component';
import { PaymentsuccessComponent } from './Clients/paymentsuccess/paymentsuccess.component';
import { RoomNumbersModalComponentComponent } from './Clients/room-numbers-modal-component/room-numbers-modal-component.component';
import { BackupcandidatesComponent } from './Clients/backupcandidates/backupcandidates.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DashboardComponent } from './Clients/dashboard/dashboard.component';
import { AddbankaccountComponent } from './Clients/addbankaccount/addbankaccount.component';


@NgModule({
  declarations: [
    AppComponent,
    UniquePipe,
    LoginComponent,
    TableComponent,
    CardComponent,
    EditDetailsComponent,
    HeaderComponent,
    RegistComponent,
    UserViewComponent,
    TableqrComponent,
    TablesearchPipe,
     ClientAdminHomeComponent,
    ChangepasswordComponent,
    AddcandidateComponent,
    AddFeeComponent,
    AddMenuComponent,
    ListfeeComponent,
    ListmenuComponent,
    ListcandidatesComponent,
    CandidateFilterPipePipe,
    CandidateDetailComponent,
    TermsandconditionsComponent,
    GetpaymenthistoryComponent,
    AdminlistfeeComponent,
    UpdateroomComponent,
    AddDayComponent,
    SearchCandidatesComponent,
    SendEmailsComponent,
    AdminlistmenuComponent,
    UpdatemenuitemsComponent,
    IndexComponent,
    LivedemoComponent,
    MyMoneyComponent,
    UpdatecandidateComponent,
    SubscriptionComponent,
    PaymentsuccessComponent,
    RoomNumbersModalComponentComponent,
    BackupcandidatesComponent,
    DashboardComponent,
    AddbankaccountComponent,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    MatCardModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatProgressBarModule,
    MatTableModule,
    CommonModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatMenuModule,
    CdkAccordionModule,
    MatSelectModule,
    CommonModule,
    MatProgressSpinnerModule,
   
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
