import { Pipe, PipeTransform } from '@angular/core';
import { AddCandidate } from './addcandidate/addcandidate';

@Pipe({
  name: 'candidateFilterPipe'
})
export class CandidateFilterPipePipe implements PipeTransform {
  transform(candidates: AddCandidate[], searchText: string): AddCandidate[] {
    if (!candidates || !searchText) {
      return candidates;
    }

    const searchTextLowerCase = searchText.toLowerCase();

    return candidates.filter(candidate => {
      const numberInputString = candidate.numberInput ? candidate.numberInput.toString() : '';

      return (
        (candidate.city && candidate.city.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.phoneNumber && candidate.phoneNumber.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.address && candidate.address.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.aadharNumber && candidate.aadharNumber.includes(searchTextLowerCase)) ||
        (candidate.fullName && candidate.fullName.toLowerCase().includes(searchTextLowerCase)) ||
        (candidate.numberInput && candidate.numberInput.toString().includes(searchTextLowerCase)) || // Convert to string before searching
        (candidate.sharing && candidate.sharing.toString().includes(searchTextLowerCase)) ||
        (numberInputString && numberInputString.includes(searchTextLowerCase))
      );
    });
  }
}
