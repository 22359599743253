import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ServicesService } from '../Clients/services.service';

// menu-item.model.ts
export interface MenuItem {
  menuItemId: number;
  itemName: string;
  timing: string;
  session: string;
  itemImage: any;
}

export interface MenuDay {
  dayId: number;
  day: string;
  menuItemsList: MenuItem[];
}

interface Room {
  roomType: number;
  monthlyRent: number;
  contactNumber: string;
  bedsAvailable: number | null; 
}

@Component({
  selector: 'app-user-view',
  templateUrl: './user-view.component.html',
  styleUrls: ['./user-view.component.css']
})

export class UserViewComponent implements OnInit {
  menuData: MenuDay[] = [];
  expandedIndex: number | null = null;
  rooms: Room[] = [];
  business: any[] = [];
  isShowSkipButton: boolean = false;
  user: any;
  showNotification: boolean = false;
  businessId: string = ''; // Initialize businessId to an empty string

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private candidateservice: ServicesService,
  ) {}

  ngOnInit() {
    this.candidateservice.currentBusinessId.subscribe((businessId) => {
      if (businessId) {
        this.getRooms(businessId); // Pass businessId to getRooms
        console.log('Navigating to /view with businessId:', businessId);
        this.router.navigate(['/user-view', businessId]);
      } else {
        console.warn('businessId has not been set yet.');
      }
    });

    this.route.queryParams.subscribe((queryParams) => {
      this.businessId = queryParams['businessId'] || ''; // Use the default value if businessId is not present
      if (this.businessId) {
        this.getMenus();
        this.getBusiness();
      }
    });
  }

  private getRooms(businessId: string) {
    if (businessId != null) {
      this.candidateservice.getRoomsData(businessId).subscribe(
        (response: Room[]) => {
          this.rooms = response.sort((a, b) => a.roomType - b.roomType);
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }

  private getMenus() {
    if (this.businessId) {
      this.candidateservice.getAllDaysByBusinessId(this.businessId).subscribe(
        (menuData) => (this.menuData = menuData as MenuDay[]) // Cast menuData to MenuDay[]
      );
    }
  }

   // Fetch business data
   private getBusiness(){
    if(this.businessId){
      this.candidateservice.getBusinessData(this.businessId).subscribe(
        (response: any) => {
          this.business = [response]; // Change to an array with a single element
          console.log('Business data:', this.business);
        },
        error => {
          console.error(error);
        }
      )
    }
   }
  

  toggleAccordion(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }

  getSafeImage(itemImage: string): SafeResourceUrl {
    // Assuming itemImage is a base64 encoded string
    const imageUrl = `data:image/png;base64,${itemImage}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }

  dismissNotification(): void {
    this.showNotification = false;
  }


  dataSaved(): void {
    this.showNotification = true;

    // Close the notification after a certain duration
    setTimeout(() => {
      this.showNotification = false;
    }, 3000);
  }
}
