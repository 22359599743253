import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ServicesService } from '../services.service';
import { CommonModule } from '@angular/common';


interface Room {
  roomType: number;
  monthlyRent: number;
  contactNumber: string;
  bedsAvailable: string | null;
}

@Component({
  selector: 'app-listfee',
  templateUrl: './listfee.component.html',
  styleUrls: ['./listfee.component.css']
})
export class ListfeeComponent {
  rooms: Room[] = [];
  business: any[] = []; 

  constructor(
    private candidateservice: ServicesService
  ) {}

  ngOnInit(): void {
  
    const businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
      this.candidateservice.getRoomsData(businessId1).subscribe(
        (response: Room[]) => {
        
          this.rooms = response.sort((a, b) => a.roomType - b.roomType);
        },
        error => {
          console.error(error);
        }
      );
  
      // Fetch business data
      this.candidateservice.getBusinessData(businessId1).subscribe(
        (response: any) => {
          this.business = [response]; // Change to an array with a single element
          console.log('Business data:', this.business);
        },
        error => {
          console.error(error);
        }
      );
    }
  }
  
  

  
}
