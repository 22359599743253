import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from '../services.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

interface Room {
  id: number;
  roomType: string; // Change to string if roomType is not a number
  monthlyRent: number;
  contactNumber: string;
}

@Component({
  selector: 'app-updateroom',
  templateUrl: './updateroom.component.html',
  styleUrls: ['./updateroom.component.css']
})
export class UpdateroomComponent {
  @Input() roomData!: Room; // Remove "!" from roomData

  RoomForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private candidateservice: ServicesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private route: ActivatedRoute
  ) {
    // Initialize the form with the received data
    this.RoomForm = this.formBuilder.group({
      roomType: [this.data.roomData.roomType, [Validators.required]],
      monthlyRent: [this.data.roomData.monthlyRent, [Validators.required]],
      contactNumber: [this.data.roomData.contactNumber, [Validators.required]],
      bedsAvailable: [this.data.roomData.bedsAvailable, [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      const roomIdValue = params['roomId']; // Use bracket notation
      // Now, roomIdValue contains the room ID from the route.
    });
  }
  

  onSubmit() {
    const formData: FormData = new FormData();
    formData.append('roomType', this.RoomForm.get('roomType')?.value);
    formData.append('monthlyRent', this.RoomForm.get('monthlyRent')?.value);
    formData.append('contactNumber', this.RoomForm.get('contactNumber')?.value);
    formData.append('bedsAvailable', this.RoomForm.get('bedsAvailable')?.value);
    // Assuming roomIdValue is obtained correctly
    const roomIdValue = this.data.roomData.id; // Replace with the actual room ID

    let businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
      this.candidateservice.UpdateFee(businessId1, roomIdValue, formData).subscribe(
        response => {
          console.log(response);
          Swal.fire({
            title: 'Data updated successfully!',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          }).then(() => {
            this.RoomForm.reset();
          });
        }
      );
    }
  }
}
