<form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()">
  <div>
    <label for="oldPassword">Old Password:</label>
    <input type="password" id="oldPassword" formControlName="oldPassword" required>
  </div>
  <div>
    <label for="newPassword">New Password:</label>
    <input type="password" id="newPassword" formControlName="newPassword" required>
    
  </div>
  <div>
    <label for="confirmNewPassword">Confirm New Password:</label>
    <input type="password" id="confirmNewPassword" formControlName="confirmNewPassword" required>
    <div *ngIf="changePasswordForm.hasError('passwordMismatch') && changePasswordForm.get('confirmNewPassword')?.touched">
      <small class="sup">Passwords do not match</small>
    </div>
  </div>
  <div>
    <label for="businessId">Business ID:</label>
    <input type="text" id="businessId" formControlName="businessId" required>
  </div>
  <div>
    <button type="submit" [disabled]="!changePasswordForm.valid">Change Password</button>
  </div>
</form>
