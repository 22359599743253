import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { UpdateroomComponent } from '../updateroom/updateroom.component';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { RoomNumbersModalComponentComponent } from '../room-numbers-modal-component/room-numbers-modal-component.component';

interface Room {
  id: number;
  roomType: string;
  monthlyRent: number;
  contactNumber: number;
  bedsAvailable: number;
  rooms: {
    id: number;
    roomNumber: string;
  }[];
}


@Component({
  selector: 'app-adminlistfee',
  templateUrl: './adminlistfee.component.html',
  styleUrls: ['./adminlistfee.component.css']
})
export class AdminlistfeeComponent implements OnInit{
    searchText: string = ''; 
    rooms: Room[] = [];
    
    businessId!: string; 
    business: any[] = [];
    currentPage: number = 1;
    itemsPerPage: number = 8;
    showUpdateForm = false;
    roomNumbers: Room[] = [];
    updateRoomData: any = {
      id: null,
      contactNumber: null,
      monthlyRent: null,
      roomType: null
    };
    
constructor(private servicesService: ServicesService,private http: HttpClient,
  private dialog: MatDialog, private matIconRegistry: MatIconRegistry,
  private domSanitizer: DomSanitizer  ){
    // Register the delete icon
    this.matIconRegistry.addSvgIcon(
      'delete',
      this.domSanitizer.bypassSecurityTrustResourceUrl('path-to-your-delete-icon.svg')
    );
  }

ngOnInit(): void {
  const businessId1 = localStorage.getItem("businessId");
  if (businessId1 != null) {
    this.businessId = businessId1; 
    this.servicesService.getRoomsData(businessId1).subscribe(
      (response: Room[]) => {
        // Convert roomType to number before sorting
        this.rooms = response.sort((a, b) => +a.roomType - +b.roomType);
      },
      error => {
        console.error(error);
      }
    );
  
  

   
    this.servicesService.getRoomsData(businessId1).subscribe(
      (response: any[]) => {
        this.business = response; // Populate the business array
        console.log('Business data:', this.business);
      },
      error => {
        console.error(error);
      }
    );
  }

  this.loadRoomNumbers();
}


deleteFee(businessId: string, roomId: number) {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this room!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel'
  }).then((result) => {
    if (result.isConfirmed) {
      this.servicesService.deleteFee(businessId, roomId).subscribe(
        response => {
          console.log('Delete response:', response);
          if (response.message && response.message.includes('deleted')) {
            Swal.fire('Deleted!', response.message, 'success');
          } else {
            Swal.fire('Error', 'An error occurred while deleting.', 'error');
          }
        },
        error => {
          console.error('Delete error:', error);
          Swal.fire('Error', 'An error occurred while deleting.', 'error');
        }
      );
    }
  });
}



addRoomNumbers(id: number) {
  const dialogRef = this.dialog.open(RoomNumbersModalComponentComponent, {
    data: { id }, // Pass the id as data to the dialog
  });

  dialogRef.afterClosed().subscribe(result => {
   // console.log('The dialog was closed', result);
    // Add any logic you want to perform after the dialog is closed
  });
}
loadRoomNumbers() {
  let businessId = localStorage.getItem("businessId");
  if (businessId != null) {
    this.servicesService.getAllRoomNumbers(businessId).subscribe(
      (response: Room[]) => {
        this.roomNumbers = response;
        console.log('Room Numbers:', this.roomNumbers);
      },
      (error) => {
        console.error('Error fetching room numbers', error);
      }
    );
  }
}
deleteRoomNumber(id: number): void {
  Swal.fire({
    title: 'Are you sure?',
    text: 'You will not be able to recover this roomNumber!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel'
  }).then((result) => {
    if (result.isConfirmed) {
      this.servicesService.deleteRoomNumberById(id).subscribe(
        response => {
          console.log('Delete response:', response);
          Swal.fire('Deleted!', 'Room deleted successfully.', 'success');
        },
        error => {
          console.error('Delete error:', error);
          Swal.fire('Error', 'An error occurred while deleting.', 'error');
        }
      );
    }
  });
}

generateBedArray(roomType: number): number[] {
  // Assuming roomType is the number of beds
  return Array.from({ length: roomType }, (_, index) => index);
}





updateRoom(id: number) {
  const room = this.rooms.find(room => room.id === id);
  const dialogRef = this.dialog.open(UpdateroomComponent, {
    data: { roomData: room }, // Pass the room data as 'roomData'
  });

  dialogRef.afterClosed().subscribe(result => {
    // Handle the result if needed
  });
}

//Pagination Links 
getPages(): number[] {
  const pages: number[] = [];
  const totalPages = this.getTotalPages();
  for(let i=1; i<=totalPages; i++) {
    pages.push(i);
  }
  return pages;
}

getTotalPages(): number {
  return Math.ceil(this.rooms.length / this.itemsPerPage);
}

getPaginatedCandidates(): any[] {
  const startIndex = (this.currentPage - 1) * this.itemsPerPage;
  const endIndex = startIndex + this.itemsPerPage;
  return this.rooms.slice(startIndex, endIndex);
}


}
