<mat-toolbar class="fixed-toolbar">
  <button mat-icon-button (click)="nav.toggle()">
    <mat-icon class="custom-icon">menu</mat-icon>
  </button>
  <span class="logo">Admin</span>
  <span class="extra"></span>
  <button class="arrow" mat-button [matMenuTriggerFor]="menu">
    <div class="tag">
      <a class="user">{{ user }}</a>
      <i class="fa-solid fa-angle-down"></i>
    </div>
  </button>

  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <a>Logout</a>
    </button>
    <button mat-menu-item>
      <a (click)="openPopUp()">Change Password</a>
    </button>
    <button mat-menu-item>
      <a (click)="openBank()">Add Bank Account</a>
    </button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="sidenav-wrapper">
  <mat-sidenav mode="side" #nav class="sidenav">
    <div class="sidenav-items">
      <button mat-button [routerLink]="['dashboard']" routerLinkActive="active-link">
        <i class="fa-solid fa-home"></i> Dashboard
      </button>

      <button mat-button [routerLink]="['candidate']" routerLinkActive="active-link">
        <i class="fa-solid fa-user-plus"></i> Add Candidate
      </button>

      <button mat-button [routerLink]="['listcandidate']" routerLinkActive="active-link">
        <i class="fa-solid fa-users"></i> List Candidate
      </button>

      <button mat-button [routerLink]="['addfee']" routerLinkActive="active-link">
        <i class="fa-solid fa-money-bill"></i> Add Room & Fees
      </button>

      <button mat-button [routerLink]="['adminlistfee']" routerLinkActive="active-link">
        <i class="fa-solid fa-list"></i> Fees and Rooms List
      </button>

     <!-- Main Menu Item -->
    <div class="main-menu">
      <button mat-button [routerLink]="['addDay']">
        <i class="fa-solid fa-calendar-plus"></i> Add Day & Menu
      </button>
      <button mat-button [routerLink]="['adminlistmenu']">
        <i class="fa-solid fa-list"></i> List Menu
      </button>
      <!-- Add more submenu items as needed -->
    </div>

      <button mat-button [routerLink]="['searchcandi']" routerLinkActive="active-link">
        <i class="fa-solid fa-search"></i> Search Candidates
      </button>

      <button mat-button [routerLink]="['sendmails']" routerLinkActive="active-link">
        <i class="fa-solid fa-envelope"></i> Announcements
      </button>

      <button mat-button [routerLink]="['mymoney']" routerLinkActive="active-link">
        <i class="fa-solid fa-wallet"></i> See Transactions
      </button>

      <button mat-button [routerLink]="['backup']" routerLinkActive="active-link">
        <i class="fa-solid fa-database"></i> Backup Candidate
      </button>

      <!-- <button mat-button [routerLink]="['subscription', businessId]" routerLinkActive="active-link">
        <i class="fa-solid fa-tags"></i> Pricing
      </button> -->
    </div>
  </mat-sidenav>

  <mat-sidenav-content class="custom-sidenav-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
