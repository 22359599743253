import { Component } from '@angular/core';
import { ServicesService } from '../services.service';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent {
  selectedPlan: string | null = '';
  subscriptionPlans: string[] = ['Monthly', 'Yearly', 'Thrice'];
  pricing: { [key: string]: number } = {};
  businessId: string = '';

  constructor(
    private subscriptionService: ServicesService,
    private route: ActivatedRoute
  ) {
    this.fetchPricing();

    this.route.params.subscribe(params => {
      console.log('Route Params:', params);
      this.businessId = params['businessId'];
    });
  }

  fetchPricing() {
    this.pricing = {
      monthly: 1000,
      yearly: 10000,
      thrice: 3000
    };
  }

  selectPlan(plan: string) {
    this.selectedPlan = plan;
  }

  initiatePayment() {
    // if (!this.businessId || !this.selectedPlan) {
    //   console.error('Business ID or selected plan is undefined.');
    //   return;
    // }

    // Check if a plan is selected
    if (!this.selectedPlan) {
      // Display SweetAlert if no plan is selected
      Swal.fire({
        icon: 'warning',
        title: 'Please select a plan',
        showConfirmButton: false,
        timer: 1500  // Automatically close after 1.5 seconds
      });
      return;
    }

    this.subscriptionService.initiatePayment(this.businessId, this.selectedPlan)
      .subscribe(
        orderId => {
          this.openRazorpayPage(orderId);
        },
        error => {
          console.error('Failed to initiate payment:', error);
          // Handle error, show error message to the user, etc.
        }
      );
  }

  openRazorpayPage(orderId: string) {
    const plan = this.selectedPlan?.toLowerCase();
    const amount = plan ? this.pricing[plan] : 100; // Default to 100 if plan is not recognized

    const options = {
      key: 'rzp_test_KdiESTDcHPC74B',
      amount: amount * 100, // Convert to paise
      currency: 'INR',
      name: 'unsofttech',
      description: 'Subscription Payment',
      order_id: orderId,
      handler: (response: any) => {
        console.log(response);
        // Handle the success callback as needed
      },
      prefill: {
        name: 'Customer Name',
        email: 'customer@example.com',
        contact: '1234567890'
      },
      theme: {
        color: '#F37254'
      }
    };

    const rzp = new (window as any).Razorpay(options);
    rzp.open();
  }
}
