import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Login } from './login';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private httpClient: HttpClient) {}

  saveLogin(login: Login): Observable<string> {
    return this.httpClient.post<any>('https://api.careerneststay.in/my/login', login).pipe(
      map((response) => {
        const token = response.token;

        // Store the token in local storage or any other appropriate location
        localStorage.setItem('token', token);

        // Get the expiration time from the token
        const expirationTime = this.getExpirationTimeFromToken(token);

        // Calculate the remaining time before expiration
        const currentTime = Date.now();
        const remainingTime = expirationTime - currentTime;

        // Set a timeout to prompt the user to re-login when the token expires
        setTimeout(() => {
          this.clearTokenAndPromptLogin();
        }, remainingTime);

        return token;
      }),
      catchError((error: HttpErrorResponse) => {
        // Handle login error
        if (error.status === 401) {
          return throwError('Invalid credentials. Please try again.');
        } else {
          return throwError('An error occurred. Please try again later.');
        }
      })
    );
  }

  private getExpirationTimeFromToken(token: string): number {
    const jwtData = token.split('.')[1];
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    const expirationTime = decodedJwtData.exp * 1000; // Convert seconds to milliseconds
    return expirationTime;
  }

  private clearTokenAndPromptLogin() {
    localStorage.removeItem('token');
    // Prompt the user to re-login or perform any other necessary actions
  }
  private getToken(): string | null {
    return localStorage.getItem('token');
  }

  private getHeaders(): HttpHeaders {
    const token = this.getToken();
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }

  getProtectedData(): Observable<any> {
    const headers = this.getHeaders();
    return this.httpClient.get<any>('https://api.careerneststay.in/my/protected-data', { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        // Handle error
        if (error.status === 401) {
          // Clear the token if unauthorized
          this.clearTokenAndPromptLogin();
        }
        return throwError('An error occurred. Please try again later.');
      })
    );
  }
}