
<div class="container-fluid">
    <h2 class="candi">Demo List</h2>

    <input [(ngModel)]="searchText" type="search" placeholder="Search by CandidateId/City/AadharNumber etc" class="form-control" style="width: 100%; margin: 10px auto;"/>
 
    <table class="w3-table-all w3-card-4">
      <thead class="thead-light">
        <tr>
          <th>fullName</th>
          <th>Email</th>
          <th>phoneNumber</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let clients of demos">
          <td> {{ clients.name}} </td>
          <td> {{ clients.email }} </td>
          <td> {{ clients.phoneNumber }} </td>
          <td>{{clients.date | date}}</td>
          <td>
        
            <button type="button" class="btn btn-danger" (click)="deleteCandidate(clients.id)">Delete</button> 
          </td>
        </tr>
      </tbody>
    </table><br>

    <router-outlet></router-outlet>
    <nav aria-label="Page navigation example">
        <ul class="pagination">
          <li class="page-item" [class.disabled]="currentPage == 1">
            <a class="page-link"  (click)="currentPage = currentPage - 1">Previous</a>
          </li>
          <li class="page-item" *ngFor="let page of getPages()" [class.active]="currentPage == page">
            <a class="page-link"  (click)="currentPage = page">{{ page }}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage == getTotalPages()">
            <a class="page-link"  (click)="currentPage = currentPage + 1">Next</a>
          </li>
        </ul>
      </nav>


