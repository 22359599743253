
    <div class="container">
    <h1>Terms and Conditions for PG Hostel Accommodation</h1>

    <p class="signature">These terms and conditions ("Agreement") govern the rental of accommodations at [Your PG Hostel Name], hereinafter referred to as the "Hostel," by the residents, hereinafter referred to as "Residents." By accepting accommodation, Residents agree to abide by the following terms and conditions:</p>

    <ol>
        <li>
            <h2>Rent and Payment:</h2>
            <p class="signature">Residents are required to pay the monthly rent of [Rent Amount] by the specified [Due Date].</p>
            <p class="signature">Prior to moving in, a security deposit of [Security Deposit Amount] is to be submitted. This deposit will be returned upon the termination of the Agreement, pending inspection.</p>
        </li>
        <li>
            <h2>Duration of Stay:</h2>
            <p>This Agreement is valid for a minimum period of [Minimum Stay Period], starting on [Move-in Date].</p>
            <p>Residents can request an extension of their stay by providing appropriate notice.</p>
        </li>
        <li>
            <h2>Termination and Notice:</h2>
            <p>Residents should provide a notice of [Notice Period] before vacating the Hostel.</p>
            <p>The Hostel reserves the right to terminate the Agreement in case of breach of terms or unacceptable behavior.</p>
        </li>
        <li>
            <h2>Housekeeping and Maintenance:</h2>
            <p>Residents are responsible for keeping their rooms and common areas clean and tidy.</p>
            <p>Hostel management will conduct regular maintenance and cleaning of common areas.</p>
        </li>
        <li>
            <h2>Quiet Hours and Guest Policy:</h2>
            <p>Quiet hours are from [Quiet Hours Start] to [Quiet Hours End].</p>
            <p>Guests are allowed during designated hours and must be registered with the management.</p>
        </li>
        <li>
            <h2>Safety and Security:</h2>
            <p>Residents are responsible for the security of their belongings.</p>
            <p>Security protocols, including curfew and access control, must be followed.</p>
        </li>
        <li>
            <h2>Utilities and Amenities:</h2>
            <p>Rent includes [Included Utilities] utilities.</p>
            <p>Amenities such as [List of Amenities] are available for residents' use.</p>
        </li>
        <li>
            <h2>Behavior and Conduct:</h2>
            <p>Residents shall treat fellow residents and staff with respect.</p>
            <p>Illegal activities, disruptive behavior, and harassment are strictly prohibited.</p>
        </li>
        <li>
            <h2>Smoking and Alcohol Policy:</h2>
            <p>Smoking is prohibited inside the Hostel premises.</p>
            <p>Alcohol consumption is allowed within limits and must comply with legal regulations.</p>
        </li>
        <li>
            <h2>Damage and Liability:</h2>
            <p>Residents are responsible for any damage caused to property or facilities.</p>
            <p>Report damages to management immediately.</p>
        </li>
        <li>
            <h2>Entry and Inspections:</h2>
            <p>Hostel management reserves the right to enter rooms for inspections and maintenance.</p>
            <p>Inspections will be conducted with prior notice, except in emergencies.</p>
        </li>
        <li>
            <h2>Storage and Personal Belongings:</h2>
            <p>Residents are responsible for their personal belongings.</p>
            <p>Do not store valuable items in common areas.</p>
        </li>
        <li>
            <h2>Health and Hygiene:</h2>
            <p>Maintain personal hygiene and cleanliness at all times.</p>
            <p>Follow health protocols, especially in communal areas.</p>
        </li>
        <li>
            <h2>Grievances and Conflict Resolution:</h2>
            <p>Any conflicts or grievances shall be reported to management for resolution.</p>
            <p>Cooperation is expected to maintain a harmonious living environment.</p>
        </li>
        <li>
            <h2>Alterations and Decorations:</h2>
            <p>Residents must seek permission before making alterations to rooms or common areas.</p>
            <p>Decorations should not damage property or disturb others.</p>
        </li>
        <li>
            <h2>Termination of Agreement:</h2>
            <p>Immediate eviction may occur for violations such as violence, drug use, or illegal activities.</p>
            <p>Breach of Agreement terms may lead to termination.</p>
        </li>
        <li>
            <h2>Changes to Terms and Conditions:</h2>
            <p>The Hostel reserves the right to modify these terms and conditions as needed. Residents will be notified of any changes.</p>
        </li>
    </ol>
    

    <p>By signing below, the Resident acknowledges reading, understanding, and agreeing to abide by the terms and conditions outlined in this Agreement.</p>

    <p>Resident's Signature: ________________________ Date: ______________</p>
    <p>Hostel Management Representative: ________________________ Date: ______________</p>
</div>
