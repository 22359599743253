import { Component } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { ChangepasswordComponent } from '../Clients/changepassword/changepassword.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  user: string | null = null;

  handleclick() {
    console.log('Button clicked!');
  }

  isShowSkipButton: boolean = false;
  showNotification!: boolean;
  constructor(private router: Router,private dialog: MatDialog) {}
  
ngOnInit(){
  this.user = localStorage.getItem('uname');

   
}

dismissNotification(): void {
  this.showNotification = false;
}

logout() {
  Swal.fire({
    title: 'Are you sure you want to log out?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, log out',
    cancelButtonText: 'Cancel',
  }).then((result) => {
    if (result.isConfirmed) {
      // User confirmed the logout
      localStorage.clear(); // Clear localStorage
      this.router.navigate(['']); // Navigate to the root URL or login page
    }
  });
}

openPopUp() {
  this.dialog.open(ChangepasswordComponent, {
    width: '400px',
    height: '450px',
  });
}

dataSaved(): void {
  this.showNotification = true;

  // Close the notification after a certain duration
  setTimeout(() => {
    this.showNotification = false;
  }, 3000);
}
}
