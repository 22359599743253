import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, filter } from 'rxjs';
import { AddCandidate } from './addcandidate/addcandidate';
import { Business } from '../table/business';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ServicesService {

  constructor(private httpClient: HttpClient,private router: Router,) {
    
    const initialId = this.extractIdFromUrl(window.location.href);
    this.businessIdSource.next(initialId);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(event => {
      const navEvent = event as NavigationEnd;
      const newId = this.extractIdFromUrl(navEvent.urlAfterRedirects);
      this.businessIdSource.next(newId);
    });
  }

  private extractIdFromUrl(url: string): string | null {
    const params = new URLSearchParams(url.split('?')[1]);
    return params.get('businessId');
  }



setBusinessId(id: string): void {
    this.businessIdSource.next(id);

   }
   

  private baseURL = "https://api.careerneststay.in/api/candidates";

  private backupcandidates = "https://api.careerneststay.in/api/getAllBackupCandidates";

  private baseURL1 = "https://api.careerneststay.in/api/save";

  private baseURL2 = "https://api.careerneststay.in/api/candidate";

  private updatecandidate = "https://api.careerneststay.in/api";

  private baseURL3 = "https://api.careerneststay.in/api/payment";

  private baseURL4 = "https://api.careerneststay.in/api/hostel-rooms/saverooms";

  private baseURL5 =  "https://api.careerneststay.in/api/hostel-rooms/getallroomsby"

  private baseURL6 =  "https://api.careerneststay.in/my/get"

  //Day
  private baseURL7 =  "https://api.careerneststay.in/api/Days/save";


  private baseURL11 =  "https://api.careerneststay.in/api/menuitems/getall";
  //Payments
  private baseURL9 =  "https://api.careerneststay.in/api/payment";


//Count candidates for BusinessId
private baseURL10 = "https://api.careerneststay.in/api"


//DueDate
private baseUrl = 'https://api.careerneststay.in/api'


//Mail Services
private apiUrl = 'https://api.careerneststay.in/api/sendFestivalEmails';

//getAllDays
private getDaysbaseUrl = 'https://api.careerneststay.in/api/Days/getAllDays';

//deleteday
private deleteDaybaseUrl = 'https://api.careerneststay.in/api/Days/delete';

private savemenubaseUrl = 'https://api.careerneststay.in/api/menuitems';

//getAllMenus
private baseUrlMenuItems = 'https://api.careerneststay.in/api/menuitems';

//DuesPaid
private apiUrlPaid = 'https://api.careerneststay.in';

//menuItems Update
private baseUrlMenuUpdate = 'https://api.careerneststay.in/api/menuitems/update';

//deletemenuItem
private deleteMenu = 'https://api.careerneststay.in/api/menuitems/delete';

//livedemo
private savelivedemo= 'https://api.careerneststay.in/api/live-demo-requests/saveLiveDemoRequest';
//livedemo
private getallivedemo= 'https://api.careerneststay.in/api/live-demo-requests/getalldemos';

//changepassword
private changepassword = 'https://api.careerneststay.in/my/changePassword';


//saveallRoomNumbers
private getallroomnumbers = 'https://api.careerneststay.in/api/rooms/getall';
//getallRoomNumbers
private saveroomnumbers = 'https://api.careerneststay.in/api/rooms/saveroomNumbers';

private createorder = 'https://api.careerneststay.in/subscriptions/initiate'; 

private deleteroombyidUrl = 'https://api.careerneststay.in/api/rooms';
private deleteroomnumbers = 'https://api.careerneststay.in/api/rooms'; 

//deleteday
private deletebackupcandidate = 'https://api.careerneststay.in/api/deletebackup';

//addbankaccountdetails
private savebankdetails = 'https://api.careerneststay.in/api/business-bank-details/savebankdetails';

private businessIdSource: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  currentBusinessId = this.businessIdSource.asObservable();

 

addCandidate(save: FormData,businessId : string): Observable<any> {
  return this.httpClient.post<any>(`${this.baseURL1}/${businessId}`,save);
}

getCandidateList(businessId : string): Observable<AddCandidate[]>{
  return this.httpClient.get<AddCandidate[]>(`${this.baseURL}/${businessId}`);
}


UpdateCandidate(id:number, formdata: FormData): Observable<any> {
  return this.httpClient.put<any>(`${this.updatecandidate}/${id}`, formdata);
}

getCandidateDetailsById(id: string): Observable<AddCandidate> {
  return this.httpClient.get<AddCandidate>(`${this.baseURL2}/${id}`);
}


makePayment(formdata: FormData, candidateId: string): Observable<any> {
  return this.httpClient.post<any>(`${this.baseURL3}/${candidateId}/make-payment`, formdata,{ responseType: 'json' } );
}


addFee(saveFee: FormData,businessId : string): Observable<any> {
  return this.httpClient.post<any>(`${this.baseURL4}/${businessId}`,saveFee);
}

private baseUrl3 = 'https://api.careerneststay.in/api/hostel-rooms/update';
UpdateFee(businessId: string, roomId: number, updateFee: FormData): Observable<any> {
  return this.httpClient.put<any>(`${this.baseUrl3}/${businessId}/${roomId}`, updateFee);
}
private baseUrl4 = 'https://api.careerneststay.in/api/hostel-rooms/delete';
deleteFee(businessId: string, roomId: number): Observable<any> {
  return this.httpClient.delete<any>(`${this.baseUrl4}/${businessId}/${roomId}`);
}


getRoomsData(businessId: string): Observable<any[]> {
  return this.httpClient.get<any[]>(`${this.baseURL5}/${businessId}`);
}

getBusinessData(businessId: string): Observable<any[]> {
  return this.httpClient.get<any[]>(`${this.baseURL6}/${businessId}`);
}

//Day
addDay(saveDay: FormData,businessId : string): Observable<any> {
  return this.httpClient.post<any>(`${this.baseURL7}/${businessId}`,saveDay);
}

//deleteday
deleteDay(dayId: number): Observable<any> {
  return this.httpClient.delete<any>(`${this.deleteDaybaseUrl}/${dayId}`);
}

getMenuListAdmin(): Observable<any[]>{
  return this.httpClient.get<any[]>(`${this.baseURL11}`);
}

getCandidatePaymentHistory(candidateId:number): Observable<any>{
  return this.httpClient.get<any[]>(`${this.baseURL9}/${candidateId}/payment-history`)
}



//Total Candidates for a businessId
countCandidatesByBusinessId(businessId: string): Observable<any> {
  const url = `${this.baseURL10}/countCandidates/${businessId}`;
  return this.httpClient.get<any>(url);
}


//mail services
sendEmails(formData: FormData) {
  return this.httpClient.post<string>(`${this.apiUrl}`, formData);
}


//getAllDays
getAllDaysByBusinessId(businessId: string): Observable<any[]> {
  return this.httpClient.get<any[]>(`${this.getDaysbaseUrl}/${businessId}`);
}

 // Function to save a menu item for a specific day
 saveMenuItem(dayId: number, businessId: string,formData: FormData): Observable<any> {
  return this.httpClient.post<any>(`${this.savemenubaseUrl}/save/${dayId}/${businessId}`, formData);
}


getAllMenusBusinessId(businessId: string): Observable<any[]> {
  return this.httpClient.get<any[]>(`${this.baseUrlMenuItems}/getall/${businessId}`);
}

updateMenuItems(itemId: number, formData: FormData): Observable<any> {
  console.log('Updating menu items:', itemId, formData);
  return this.httpClient.put<any>(`${this.baseUrlMenuUpdate}/${itemId}`,formData);
}



 deleteMenuItem(itemId: number): Observable<any> {
    const url = `${this.deleteMenu}/${itemId}`;
    return this.httpClient.delete(url);
  }



  addLiveDemo(saveDay: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this.savelivedemo}`,saveDay);
  }

  getLiveDemo(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.getallivedemo}`);
  }
  

  changePassword(formData: FormData): Observable<any> {
    const headers = new HttpHeaders(); // No need to set Content-Type, Angular will set it for FormData
    return this.httpClient.put<any>(this.changepassword, formData, { headers });
  }

  saveRoomNumbers(formData: FormData, hostelRoomId: number, businessId: string): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.saveroomnumbers}/${hostelRoomId}/${businessId}`, formData);
  }
  

  getAllRoomNumbers(businessId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.getallroomnumbers}/${businessId}`);
  }


  getAllBackUpCandidates(businessId: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.backupcandidates}/${businessId}`);
  }
  
  deleteRoomNumberById(id: number): Observable<void> {
    return this.httpClient.delete<void>(`${this.deleteroomnumbers}/${id}`);
  }
  deleteBackupCandidate(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.deletebackupcandidate}/${id}`);
  }

  saveBankDetails(addbank: FormData): Observable<any> {
    return this.httpClient.post<any>(`${this.savebankdetails}`,addbank);
  }

  private remainder = 'https://api.careerneststay.in/api/sendReminder';
  sendReminder(candidate: any){
    return this.httpClient.post<any>(`${this.remainder}`,candidate);
  }
 
  private order = 'https://api.careerneststay.in/subscriptions';
  initiatePayment(businessId: string, planType: string): Observable<string> {
    const url = `${this.order}/initiate/${businessId}?planType=${planType}`;
    return this.httpClient.post<string>(url, {});
  }

  private sendwhatsapp = 'https://api.careerneststay.in/api/send-whatsapp-message'; // Replace with your actual API base URL
  sendWhatsappMessage(id: number): Observable<any> {
    return this.httpClient.post<any>(`${this.sendwhatsapp}/${id}`, {});
  }
}