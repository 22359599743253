import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardService {
  private url2 = "https://api.careerneststay.in/my/count";
  // private url2 = "http://15.206.23.8:8085/my/count";

  constructor(private httpClient: HttpClient) { }

  countData(): Observable<number> {
    return this.httpClient.get<number>(`${this.url2}`);
  }
}
