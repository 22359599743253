import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root',
})
export class EditDetailsService {
  private url2 = 'https://api.careerneststay.in/my/update';
  private url3 = 'https://api.careerneststay.in/my/typeupdate';

  constructor(private httpClient: HttpClient) {}

  updateDetails(businessId: string, formData: any): Observable<any> {
    return this.httpClient.put<any>(`${this.url2}/${businessId}`, formData);
  }



  
  updateDetails1(typeid: number, formData: any): Observable<any> {
    return this.httpClient.put<any>(`${this.url3}/${typeid}`, formData);
  }



}
