

<div class="search-container">
  <label for="businessId">Business ID: </label>
  <input type="text" id="businessId" [(ngModel)]="businessId" class="input-field">

  <label for="month">Select a month: </label>
  <select id="month" [(ngModel)]="selectedMonth" class="input-field">
    <option value="1">January</option>
    <option value="2">February</option>
    <option value="3">March</option>
    <option value="4">April</option>
    <option value="5">May</option>
    <option value="6">June</option>
    <option value="7">July</option>
    <option value="8">August</option>
    <option value="9">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
  </select>

  <label for="year">Select a year: </label>
  <input type="text" id="year" [(ngModel)]="selectedYear" class="input-field" placeholder="enter year">

  <button (click)="searchCandidates()" class="search-button">Search</button>
</div>
<!-- <div class="search-container">
  <label for="businessId">Business ID: </label>
  <input type="text" id="businessId" [(ngModel)]="businessId" class="input-field">

  <label for="month">Select a month: </label>
  <select id="month" [(ngModel)]="selectedMonth" class="input-field">
    <option value="1">January</option>
    <option value="2">February</option>
    <option value="3">March</option>
    <option value="4">April</option>
    <option value="5">May</option>
    <option value="6">June</option>
    <option value="7">July</option>
    <option value="8">August</option>
    <option value="9">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
  </select>

  <button (click)="searchCandidates()" class="search-button">Search</button>
</div> -->


  <div class="container">
    <div class="row">
      <div class="col-md-6">

  <div *ngIf="candidatesWithPaidFees">
        <input [(ngModel)]="searchText" type="search" placeholder="Search by CandidateId/City/AadharNumber etc" class="form-control" style="width: 100%; margin: 10px auto;"/>

    <h2>Candidates with Paid Fees for Month {{ selectedMonth }}</h2>
    <!-- <p>Total Fees Collected for Month {{ selectedMonth }}: {{ calculateTotalFeesForMonth(selectedMonth) }}</p> -->

    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>phoneNumber</th>
          <th>RoomNo</th>
          <!-- Add other candidate properties as needed -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let candidate of candidatesWithPaidFees | candidateFilterPipe:searchText">
          <td>{{ candidate.fullName }}</td>
          <td>{{ candidate.phoneNumber }}</td>
          <td>{{ candidate.numberInput }}</td>
          <!-- Add other candidate properties as needed -->
        </tr>
        
      </tbody>
    </table>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item" [class.disabled]="currentPage == 1">
          <a class="page-link"  (click)="currentPage = currentPage - 1">Previous</a>
        </li>
        <li class="page-item" *ngFor="let page of getPages()" [class.active]="currentPage == page">
          <a class="page-link"  (click)="currentPage = page">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage == getTotalPages()">
          <a class="page-link"  (click)="currentPage = currentPage + 1">Next</a>
        </li>
      </ul>
    </nav>
  </div>
  </div>



  <div class="col-md-6">
  <div *ngIf="notPaidCandidates">
    <input [(ngModel)]="searchText" type="search" placeholder="Search by CandidateId/City/AadharNumber etc" class="form-control" style="width: 100%; margin: 10px auto;"/>

    <h2>Candidates Not Paid for Month {{ selectedMonth }}</h2>

    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>phoneNumber</th>
          <th>RoomNo</th>
          <th>Due Amount</th>
          <!-- Add other candidate properties as needed -->
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let candidate of notPaidCandidates | candidateFilterPipe:searchText">
          <td>{{ candidate.fullName }}</td>
          <td>{{ candidate.phoneNumber }}</td>
          <td>{{ candidate.numberInput }}</td>
          <td>{{ candidate.balance }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
</div>
</div>