
import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

// menu-item.model.ts
export interface MenuItem {
  menuItemId: number;
  itemName: string;
  timing: string;
  session: string;
  itemImage: any;
}


export interface MenuDay {
  dayId: number;
  day: string;
  menuItemsList: MenuItem[];
}


@Component({
  selector: 'app-listmenu',
  templateUrl: './listmenu.component.html',
  styleUrls: ['./listmenu.component.css']
})
export class ListmenuComponent implements OnInit {
  menuData: MenuDay[] = [];
  expandedIndex: number | null = null;
  businessId: any;

  constructor(private http: HttpClient,private sanitizer: DomSanitizer,
    private candidateservice: ServicesService,) { }

  ngOnInit(): void {
    // Fetch menu data from your backend
    // this.http.get<MenuDay[]>('https://api.careerneststay.in/api/Days/getAllDays/${businessId}')
    //   .subscribe(menuData => this.menuData = menuData);

      this.getMenus();
  }

  private getMenus(){
    let businessId1 = localStorage.getItem("businessId");
  if (businessId1 != null) {
    this.candidateservice.getAllDaysByBusinessId(businessId1).subscribe(menuData => this.menuData = menuData);
   
}
  }

  toggleAccordion(index: number): void {
    this.expandedIndex = this.expandedIndex === index ? null : index;
  }


  getSafeImage(itemImage: string): SafeResourceUrl {
    // Assuming itemImage is a base64 encoded string
    const imageUrl = `data:image/png;base64,${itemImage}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
}



