<div class="container naresh">
    <div class="title">Add Fee Details</div>
    <div class="content">
      <form [formGroup]="RoomForm" (ngSubmit)="onSubmit()">
        <div class="user-details">
  
          <div class="input-box">
            <span class="details">Sharing Number<sup class="sup">*</sup></span>
            <input type="text" formControlName="roomType" required>
           
          </div>

          <div class="input-box">
            <span class="details">monthlyRent<sup class="sup">*</sup></span>
            <input type="text" formControlName="monthlyRent" required>
           
          </div>

          <div class="input-box">
            <span class="details">contactNumber<sup class="sup">*</sup></span>
            <input type="text" formControlName="contactNumber" required>
           
          </div>

          <div class="input-box">
            <span class="details">BedsAvailable<sup class="sup">*</sup></span>
            <input type="text" formControlName="bedsAvailable" required>
           
          </div>
         
      <div class="button">
        <input type="submit" value="Add fee">
      </div>
    
      </div>
    </form>
    </div>
    </div>