<div class="container-fluid">
  <h2 class="candi">Fee List</h2>

  <div class="table-responsive">
    <table class="table table-bordered">
      <thead class="thead-light">
        <tr>
          <th>Contact Number</th>
          <th>Monthly Rent</th>
          <th>Sharing Number</th>
          <th>Beds Available</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let candidate of rooms">
          <td>{{ candidate.contactNumber }}</td>
          <td>{{ candidate.monthlyRent }}</td>
          <td>{{ candidate.roomType }}</td>
          <td>{{ candidate.bedsAvailable }}</td>
          <td>
            <button mat-icon-button class="btn btn-info" (click)="addRoomNumbers(candidate.id)"><mat-icon>add</mat-icon></button>
            <button mat-icon-button class="btn btn-primary" (click)="updateRoom(candidate.id)"><mat-icon>edit</mat-icon></button>
            <button mat-icon-button class="btn btn-danger" (click)="deleteFee(businessId, candidate.id)"><mat-icon>delete</mat-icon></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div *ngFor="let candidate of rooms">
  <div class="room-list-container" *ngIf="candidate.rooms.length > 0; else noRoomNumbers">
    <h2>Room Numbers List for {{ candidate.roomType }} Sharing</h2>
    <ul class="room-list">
      <li *ngFor="let room of candidate.rooms" class="room-item">
        <div class="room-info">
          <span>{{ room.roomNumber }}</span>
          <!-- <span *ngFor="let bed of generateBedArray(+candidate.roomType)" class="bed-icon"><i class="fas fa-bed" style="color: green;"></i>
          </span> -->
          <span *ngFor="let bed of generateBedArray(+candidate.roomType)" class="bed-icon">🛏️</span>
        </div>
        <button type="button" class="btn btn-danger" (click)="deleteRoomNumber(room.id)">Delete</button>
      </li>
    </ul>
  </div>
</div>

<ng-template #noRoomNumbers>
  <p class="no-room-message">No room numbers available for this Sharing.</p>
</ng-template>


