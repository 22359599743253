<div class="container naresh">
  <div class="title">Add Day</div>
  <div class="content">
    <form [formGroup]="DayForm" (ngSubmit)="onSubmit()">
      <div class="user-details">
        <div class="form-group">
          <mat-select formControlName="day" class="form-control" placeholder="Select Day">
            <mat-option value="" disabled selected hidden class="white-text">Select Day</mat-option>
            <mat-option value="Sunday">Sunday</mat-option>
            <mat-option value="Monday">Monday</mat-option>
            <mat-option value="Tuesday">Tuesday</mat-option>
            <mat-option value="Wednesday">Wednesday</mat-option>
            <mat-option value="Thursday">Thursday</mat-option>
            <mat-option value="Friday">Friday</mat-option>
            <mat-option value="Saturday">Saturday</mat-option>
          </mat-select>
        </div>
        <div class="button">
          <button type="submit" class="btn btn-primary">Add Day</button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="container-fluid">
  <h2 class="candi">Day List</h2>
  <table class="w3-table-all w3-card-4">
    <thead class="thead-light">
      <tr>
        <th>Day</th>
        <th>Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let day of days">
        <td>{{ day.day }}</td>
        <td>
          <button class="btn btn-primary" (click)="addMenu(day.dayId)">Add Menu</button>
          <button class="btn btn-danger" (click)="deleteDay(day.dayId)">Delete</button>

        </td>
      </tr>
    </tbody>
  </table>
</div>
