<!-- <div>
  <h2>Total Fees</h2>
  <p *ngIf="totalFees !== undefined">Total Fees: {{ totalFees | currency:'INR':'symbol':'1.2-2' }}</p>
  <p *ngIf="totalFees === undefined">Loading...</p>

</div> -->
<!-- <div>
  <label for="businessId">Enter Business ID:</label>
  <input type="text" id="businessId" [(ngModel)]="businessId" />
  <button (click)="getTotalFees()">Get Total Fees</button>

  <h2>Total Fees</h2>
  <p *ngIf="totalFees !== undefined">Total Fees: {{ totalFees | currency:'INR':'symbol':'1.2-2' }}</p>
  <p *ngIf="totalFees === undefined">Loading...</p>
</div> -->
<div class="container"> 
  <label for="businessId">Enter Business ID:</label>
  <input type="text" id="businessId" [(ngModel)]="businessId" />
  <button (click)="getTotalFees()">Get Total Fees</button>

  <h2>Total Fees</h2>
  <p *ngIf="totalFees !== undefined">Total Fees: {{ totalFees | currency:'INR':'symbol':'1.2-2' }}</p>
  <p *ngIf="totalFees === undefined">Loading...</p>

  <h2>Fees Summary</h2>
  <label for="selectedMonth">Select Month:</label>
  <select id="selectedMonth" [(ngModel)]="selectedMonth">
    <option value="1">January</option>
    <option value="2">February</option>
    <option value="3">March</option>
    <option value="4">April</option>
    <option value="5">May</option>
    <option value="6">June</option>
    <option value="7">July</option>
    <option value="8">August</option>
    <option value="9">September</option>
    <option value="10">October</option>
    <option value="11">November</option>
    <option value="12">December</option>
  </select>
  

  <label for="selectedYear">Select Year:</label>
  <input type="number" id="selectedYear" [(ngModel)]="selectedYear" />

  <button (click)="getFeesSummary()">Get Fees Summary</button>

  <p *ngIf="feesSummary.totalPaidFees !== undefined">Paid Fees: {{ feesSummary.totalPaidFees | currency:'INR':'symbol':'1.2-2' }}</p>
  <p *ngIf="feesSummary.totalPaidFees === undefined">Loading...</p>
  
  <p *ngIf="feesSummary.totalDues !== undefined">Dues: {{ feesSummary.totalDues | currency:'INR':'symbol':'1.2-2' }}</p>
  <p *ngIf="feesSummary.totalDues === undefined">Loading...</p>
</div>

