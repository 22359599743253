import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  changePasswordForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private service: ServicesService,
    private router: Router ,
    private dialogRef: MatDialogRef<ChangepasswordComponent> 
  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmNewPassword: ['', Validators.required],
      businessId: ['', Validators.required]
    }, { validators: this.passwordsMatchValidator });
  }

  onSubmit() {
    if (this.changePasswordForm.valid) {
      const formData = new FormData();
      formData.append('oldPassword', this.changePasswordForm.get('oldPassword')?.value);
      formData.append('newPassword', this.changePasswordForm.get('newPassword')?.value);
      formData.append('businessId', this.changePasswordForm.get('businessId')?.value);

      this.service.changePassword(formData)
        .subscribe(
          response => {
            console.log(response);
            Swal.fire({
              icon: 'success',
              title: 'Success!',
              text: 'Password changed successfully.',
            }).then(() => {
              localStorage.clear();
              this.router.navigate(['']);
              this.dialogRef.close(); 
            });
          },
          error => {
            console.error(error);
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: 'Invalid old password.',
            });
           
          }
        );
    }
  }

  // Custom validator to check if new password and confirm password match
  private passwordsMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const newPassword = control.get('newPassword')?.value;
    const confirmNewPassword = control.get('confirmNewPassword')?.value;

    return newPassword === confirmNewPassword ? null : { 'passwordMismatch': true };
  }

  
}
