<div class="container-fluid">
  <h2 class="candi">Candidate List</h2>

  <input [(ngModel)]="searchText" type="search" placeholder="Search by CandidateId/City/AadharNumber etc" class="form-control" style="width: 100%; margin: 10px auto;" />

  <div *ngIf="loading; else candidateList">
    <div class="spinner-container">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <ng-template #candidateList>
    <div *ngFor="let candidate of getPaginatedCandidates() | candidateFilterPipe:searchText" class="candidate-card">
      <div class="card">
        <div class="card-header">
          <h5 class="card-title">{{ candidate.fullName }}</h5>
        </div>
        <div class="card-body">
          <p class="card-text"><strong>Phone Number:</strong> {{ candidate.phoneNumber }}</p>
          <p class="card-text"><strong>Room Number:</strong> {{ candidate.numberInput }}</p>
          <p class="card-text"><strong>Sharing:</strong> {{ candidate.sharing }}</p>
          <p class="card-text"><strong>Joining Date:</strong> {{ candidate.date | date }}</p>
        </div>
        <div class="card-footer">
          <button mat-icon-button class="btn btn-primary" (click)="viewCandidateDetails(candidate.id.toString())">
            <mat-icon color="info" style="font-size: 25px;">visibility</mat-icon> 
          </button>

          <button class="btn btn-primary" (click)="getpaymentHistory(candidate.id.toString())">
            <mat-icon>history</mat-icon> 
          </button>

          <button mat-icon-button type="button" class="btn btn-success" (click)="updateCandidate(candidate.id)">
            <mat-icon color="success">edit</mat-icon> 
          </button>

          <button mat-icon-button type="button" class="btn btn-danger" (click)="deleteCandidate(candidate.id)">
            <mat-icon color="warn">delete</mat-icon> 
          </button>
        </div>
      </div>
    </div>

    <router-outlet></router-outlet>
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item" [class.disabled]="currentPage == 1">
          <a class="page-link" (click)="currentPage = currentPage - 1">Previous</a>
        </li>
        <li class="page-item" *ngFor="let page of getPages()" [class.active]="currentPage == page">
          <a class="page-link" (click)="currentPage = page">{{ page }}</a>
        </li>
        <li class="page-item" [class.disabled]="currentPage == getTotalPages()">
          <a class="page-link" (click)="currentPage = currentPage + 1">Next</a>
        </li>
      </ul>
    </nav>
  </ng-template>
</div>



<!-- 
  <div *ngIf="voiceActiveSectionDisabled; else voicesection;">
    <button type="button" (click)="startVoiceRecognition()">Record</button>
  </div>
  <ng-template #voicesection>
    <ng-container *ngIf="!voiceActiveSectionError; else failure">
      <ng-container *ngIf="voiceText; else start">
          <span>{{voiceText}}</span>
      </ng-container>
      <ng-template #start>
        <ng-container
          *ngIf="voiceActiveSectionListening; else beginning">
          <span>Listening...</span>
        </ng-container>
        <ng-template #beginning>
          <span>Start talking...</span>
        </ng-template>
      </ng-template>
    </ng-container>
    <ng-template #failure>
      <span>Didn't catch that</span>
    </ng-template>
    <div>
      <button (click)="closeVoiceRecognition()">Close</button>
      <button (click)="startVoiceRecognition()">Restart</button>
    </div>
  </ng-template> -->
 