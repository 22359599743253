<a href="javascript:history.back()" class="go-back-button">Go Back</a>
<div class="payment-history-container">
 
    <h2 class="payment-history-title">Payment History for {{ paymentHistory.fullName }}</h2>
  
    <ul *ngIf="paymentHistory?.paymentHistory" class="payment-list">
      <li *ngFor="let payment of paymentHistory.paymentHistory" class="payment-item">
        <p class="payment-detail">Date: {{ payment.paymentDate | date:'yyyy-MM-dd hh:mm a'  }}</p>
        <p class="payment-detail">Amount: {{ payment.amount }}</p>
        <p class="payment-detail">Payment Method: {{ payment.paymentMethod }}</p>
        <p class="payment-detail">transactionId: {{ payment.transactionId }}</p>
      </li>
    </ul>
  </div>

  
  