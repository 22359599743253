<!-- 

  <div class="container-fluid">
  
    <h2 class="candi">Menu List</h2> 
    <table class="w3-table-all w3-card-4">
      <thead class="thead-light">
        <tr>
          <th>ItemName</th>
          <th>timing</th>
          <th>session</th>
          <th>itemImage</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let menuItem of menuItems">
            <td> {{ menuItem.itemName }} </td>
          <td> {{ menuItem.timing }} </td>
          <td> {{ menuItem.session }} </td>
          <td><img [src]="getSafeImage(menuItem.itemImage)" alt="Menu Item Image" class="photo"> </td>
       
          <td>
            <button mat-raised-button color="primary">Update</button>
            <button type="button" class="btn btn-danger">Delete</button>
          </td>
          
         
        </tr>
      </tbody>
    </table><br>





 -->

 <!-- listmenu.component.html -->
 <h2 style="text-align: center;">Menu List</h2> 
 <div class="menu-container">
  <table>
    <thead>
      <tr>
        <th>Day</th>
        <th>Timing</th>
        <th>Item Name</th>
        <th>Image</th>
        <th>Action</th> <!-- Add a new column for actions -->
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let menuDay of menuData; let dayIndex = index;">
        <tr *ngFor="let menuItem of menuDay.menuItemsList; let itemIndex = index;">
          <td>{{ dayIndex === 0 ? menuDay.day : '' }}</td>
          <td>{{ menuItem.timing }}</td>
          <td>{{ menuItem.itemName }}</td>
          <td>
            <img [src]="getSafeImage(menuItem.itemImage)" alt="Menu Item Image" class="photo">
          </td>
          <td>
            <button class="delete-button" (click)="deleteItem(menuItem.menuItemId)">Delete</button>
            <button class="edit-button" (click)="editItem(menuItem, menuDay, itemIndex)">Edit</button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>


