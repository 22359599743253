import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicesService } from '../services.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DomSanitizer } from '@angular/platform-browser';
import { ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-candidate-detail',
  templateUrl: './candidate-detail.component.html',
  styleUrls: ['./candidate-detail.component.css']
})
export class CandidateDetailComponent implements OnInit {
  candidateId!: string;
  candidate: any = {}; 
  photo: any;
  paymentForm!: FormGroup;
  isLoading = false;
  isUPISelected: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private candidateservice: ServicesService,
    private formBuilder: FormBuilder,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private dialog: MatDialog

  ) {}

  ngOnInit(): void {
    this.paymentForm = this.formBuilder.group({
      paymentAmount: [null, [Validators.required]],
      paymentMonth: [null, [Validators.required]],
      paymentMethod:[null,[Validators.required]],
      transactionId: [null],
    });

    this.route.paramMap.subscribe(params => {
      this.candidateId = params.get('id')!;
      this.getCandidateDetails();
    });





  
  }

  
  getCandidateDetails(): void {
    this.candidateservice.getCandidateDetailsById(this.candidateId).subscribe(
      data => {
        this.candidate = data;
        this.photo = data.file;
        // Sanitize the photoBase64 data
        if (this.candidate.photoBase64) {
          this.candidate.safePhotoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:image/*;base64,${this.candidate.photoBase64}`);
        }
      },
      error => {
        console.error('Error fetching candidate details', error);
      }
    );
  }
  onFileSave(event :any){
const file = event.target.files[0];
const reader = new FileReader();
reader.onload = () =>{
  this.photo = reader.result as string;
};
reader.readAsDataURL(file);
  }

  getBase64Image(photo: string): string{
    return 'data:image/jpeg;base64,' + photo;
  }



  // sendReminder(): void {
  //   this.openComingSoonDialog();
  // }

  sendReminder(): void {
    this.candidateservice.sendReminder(this.candidate).subscribe(
      () => {
        // Handle success (e.g., show a success message)
        console.log('Reminder sent successfully');
        // Optionally, you can show a success message using a library like Swal
        Swal.fire('Reminder sent successfully', '', 'success');
      },
      (error) => {
        // Handle error (e.g., show an error message)
        console.error('Error sending reminder', error);
        // Optionally, you can show an error message using Swal
        Swal.fire('Error sending reminder', '', 'error');
      }
    );
  }

  openComingSoonDialog(): void {
    const dialogRef = this.dialog.open(ComingSoonDialogComponent, {
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      // Handle dialog close event if needed
    });
  }
  
  toggleTransactionIdField() {
    const selectedPaymentMethod = this.paymentForm.get('paymentMethod')?.value;
    this.isUPISelected = selectedPaymentMethod === 'UPI';
  
    // If you want to reset the transactionId field when switching payment methods
    if (!this.isUPISelected) {
      this.paymentForm.get('transactionId')?.reset();
    }
  }
 
  
  submitPayment() {
    this.isLoading = true;
    setTimeout(() => {
      if (this.paymentForm.valid) {
        const formData: FormData = new FormData();
        formData.append('paymentMonth', this.paymentForm.get('paymentMonth')?.getRawValue());
        formData.append('paymentAmount', this.paymentForm.get('paymentAmount')?.getRawValue());
        formData.append('paymentMethod', this.paymentForm.get('paymentMethod')?.getRawValue());
        formData.append('transactionId', this.paymentForm.get('transactionId')?.getRawValue());

        this.candidateservice.makePayment(formData, this.candidateId)
          .subscribe(
            response => {
              console.log(response);
              Swal.fire({
                icon: 'success',
                title: 'Payment Successful',
                text: 'Your payment has been successfully processed.',
                confirmButtonText: 'OK'
              }).then(() => {
                // Update candidate details immediately after successful payment
                this.paymentForm.reset();
                this.getCandidateDetails();
                this.isLoading = false;
              });
            },
            error => {
              console.error(error);
              Swal.fire({
                icon: 'error',
                title: 'Payment Failed',
                text: 'The Candidate already paid the fee',
                confirmButtonText: 'OK'
              });
              this.isLoading = false;
            }
          );
      }
    }, 1000); // Adjust the delay as needed
  }
  
  months = [
    { value: 1, name: 'January' },
    { value: 2, name: 'February' },
    { value: 3, name: 'March' },
    { value: 4, name: 'April' },
    { value: 5, name: 'May' },
    { value: 6, name: 'June' },
    { value: 7, name: 'July' },
    { value: 8, name: 'August' },
    { value: 9, name: 'September' },
    { value: 10, name: 'October' },
    { value: 11, name: 'November' },
    { value: 12, name: 'December' }
  ];
 


  
}

@Component({
  selector: 'app-coming-soon-dialog',
  template: `
  <div class="container">
    <h2 class="dialog-title">Coming Soon</h2>
    <p class="dialog-text">This feature will be available in the future.</p>
    <button mat-button class="dialog-close-btn" (click)="dialogRef.close('close')">Close</button>
    </div>
  `,
  styles: [`
    .dialog-title {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }

    .dialog-text {
      font-size: 1rem;
      margin-bottom: 20px;
    }

    .dialog-close-btn {
      background-color: #007bff; /* Blue color */
      color: #ffffff; /* White text color */
      padding: 10px 15px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }

    .dialog-close-btn:hover {
      background-color: #0056b3; /* Darker blue color on hover */
    }
  `],
})
export class ComingSoonDialogComponent {
  constructor(public dialogRef: MatDialogRef<ComingSoonDialogComponent>) {}
}


