<header>
  <div class="container">
    <div class="row">
      <div class="col-md-10">
        <h1>ZAPSTEL</h1>
        <p>Your Solution for Effortless Hostel Management</p>
      </div>
      <div class="col-md-2">
        <section class="login">
          <button class="btn" (click)="navigateLogin()">Login</button>
        </section>
      </div>
    </div>
  </div>
</header>




  <section class="features">
    <div class="container">
      <div class="qr-code-image">
        <img src="./assets/room.jpg" alt="Feature 1 Icon">
        <h2>Room Allocation</h2>
        <p>Efficiently allocate rooms to residents with our easy-to-use room allocation system.</p>
      </div>

      <div class="qr-code-image">
        <img src="./assets/expense-management.jpg" alt="Feature 3 Icon">
        <h2>Expense Management</h2>
        <p>Streamline expense tracking and manage hostel finances with our comprehensive system.</p>
      </div>
    </div>
  </section>

  <section class="cta">
    <div class="container">
      <h2>Ready to Simplify Hostel Management?</h2>
      <p>Try our PG Hostel Management system today and experience the difference.</p>
      <a href="tel:8125977523" class="btn">Contact Us</a>
    </div>
  </section>
  <section class="mail">
    <div class="container">
      <h2>Email Communication</h2>
      <p>Stay connected with hostellers through our advanced email communication system.</p>
      
      <div class="mail-features">
        <h3>Key Features:</h3>
        <ul>
          <li>Instant messaging for quick updates</li>
          <li>Announcements and event notifications</li>
          <li>Customizable email templates</li>
        </ul>
      </div>
  
      <p>Our email communication system ensures seamless interaction between hostel administrators and residents. Experience effective communication and keep everyone informed about important events, announcements, and more.</p>
  
      <button class="btn">Send Email</button>
    </div>
  </section>
  
  
  <section class="fee-reminder">
    <div class="container">
      <h2>Fee Reminder</h2>
      <p>Send timely fee reminders to ensure smooth financial transactions.</p>
      
      <div class="reminder-details">
        <h3>Key Features:</h3>
        <ul>
          <li>Automated email notifications for pending fees</li>
          <li>Customizable reminder intervals</li>
          <li>Track payment status in real-time</li>
        </ul>
      </div>
      
      <p>Our fee reminder system is designed to make the fee collection process hassle-free and efficient. With customizable features, you can tailor the reminders to suit your hostel's specific needs.</p>
      
      <button class="btn">Send Reminder</button>
    </div>
  </section>
  


  <section class="live-demo">
    <div class="container">
      <div *ngIf="isLoading" class="loading-overlay">
        <div class="spinner"></div>
        <div class="loading-text">Submitting...</div>
      </div>
      <h2>Request Live Demo</h2>
      <p>Interested in seeing our PG Hostel Management system in action? Request a live demo today!</p>
  
      <form [formGroup]="liveDemoForm" (ngSubmit)="onSubmit()" class="row">
        <!-- Name field -->
        <div class="col-md-6">
          <label for="name">Your Name:</label>
          <input type="text" id="name" formControlName="name" [ngClass]="{ 'form-control-error': liveDemoForm.get('name')?.invalid && liveDemoForm.get('name')?.touched }">
          <div *ngIf="liveDemoForm?.get('name')?.hasError('required') && liveDemoForm?.get('name')?.touched" class="error-message">
            Name is required.
          </div>
        </div>
  
        <!-- Email field -->
        <div class="col-md-6">
          <label for="email">Your Email:</label>
          <input type="email" id="email" formControlName="email" [ngClass]="{ 'form-control-error': liveDemoForm.get('email')?.invalid && liveDemoForm.get('email')?.touched }">
          <div *ngIf="liveDemoForm?.get('email')?.hasError('required') && liveDemoForm?.get('email')?.touched" class="error-message">
            Email is required.
          </div>
          <div *ngIf="liveDemoForm?.get('email')?.hasError('email') && liveDemoForm?.get('email')?.touched" class="error-message">
            Please enter a valid email address.
          </div>
        </div>
  
        <!-- Phone Number field -->
        <div class="col-md-6">
          <label for="phoneNumber">Phone Number:</label>
          <input type="tel" id="phoneNumber" formControlName="phoneNumber"
                 [ngClass]="{ 'form-control-error': liveDemoForm.get('phoneNumber')?.invalid && liveDemoForm.get('phoneNumber')?.touched }">
          <div *ngIf="liveDemoForm?.get('phoneNumber')?.hasError('required') && liveDemoForm?.get('phoneNumber')?.touched" class="error-message">
            Phone number is required.
          </div>
          <div *ngIf="liveDemoForm?.get('phoneNumber')?.hasError('pattern') && liveDemoForm?.get('phoneNumber')?.touched" class="error-message">
            Please enter a valid 10-digit phone number without spaces or dashes.
          </div>
        </div>
  
        <!-- Organization/Hostel Name field -->
        <div class="col-md-6">
          <label for="organization">Organization/Hostel Name:</label>
          <input type="text" id="organization" formControlName="organization"
                 [ngClass]="{ 'form-control-error': liveDemoForm.get('organization')?.invalid && liveDemoForm.get('organization')?.touched }">
          <div *ngIf="liveDemoForm?.get('organization')?.hasError('required') && liveDemoForm?.get('organization')?.touched" class="error-message">
            Organization/Institution name is required.
          </div>
        </div>
<!-- Number of Hostel Residents field -->
<div class="col-md-6">
  <label for="residents">Number of Hostel Residents:</label>
  <input type="number" id="residents" formControlName="residents"
         [ngClass]="{ 'form-control-error': liveDemoForm.get('residents')?.invalid && liveDemoForm.get('residents')?.touched }">
  <div *ngIf="liveDemoForm?.get('residents')?.hasError('required') && liveDemoForm?.get('residents')?.touched" class="error-message">
    Number of residents is required.
  </div>
</div>

<!-- Area/Locality field -->
<div class="col-md-6">
  <label for="areaLocality">Area/Locality:</label>
  <input type="text" id="areaLocality" formControlName="areaLocality"
         [ngClass]="{ 'form-control-error': liveDemoForm.get('areaLocality')?.invalid && liveDemoForm.get('areaLocality')?.touched }">
  <div *ngIf="liveDemoForm?.get('areaLocality')?.hasError('required') && liveDemoForm?.get('areaLocality')?.touched" class="error-message">
    Area/Locality is required.
  </div>
</div>

<!-- Preferred Demo Date and Time field -->
<div class="col-md-6">
  <label for="demoDateTime">Preferred Demo Date and Time:</label>
  <input type="datetime-local" id="demoDateTime" formControlName="demoDateTime"
         [ngClass]="{ 'form-control-error': liveDemoForm.get('demoDateTime')?.invalid && liveDemoForm.get('demoDateTime')?.touched }">
  <div *ngIf="liveDemoForm?.get('demoDateTime')?.hasError('required') && liveDemoForm?.get('demoDateTime')?.touched" class="error-message">
    Preferred demo date and time are required.
  </div>
</div>

<!-- Additional Comments/Requirements field -->
<div class="col-md-6">
  <label for="comments">Additional Comments/Requirements:</label>
  <textarea id="comments" formControlName="comments" rows="4"
            [ngClass]="{ 'form-control-error': liveDemoForm.get('comments')?.invalid && liveDemoForm.get('comments')?.touched }"></textarea>
  <div *ngIf="liveDemoForm?.get('comments')?.hasError('required') && liveDemoForm?.get('comments')?.touched" class="error-message">
    Message is required.
  </div>
</div>

<div class="button">
  <button type="submit" [disabled]="isLoading">
    {{ isLoading ? 'Submitting...' : 'LiveDemo' }}
  </button>
</div> 
        <!-- <button type="submit" class="btn">Request Demo</button> -->
      </form>
    </div>
  </section>
  
  <section class="qr-code">
    <div class="container">
      <h2>Scan QR Code for Fee and Menu Details</h2>
      <p>Use your mobile device to scan the QR code and access detailed information about fees and menu options.</p>
  
      <div class="qr-code-image">
        <!-- Add your QR code image here -->
        <img src="./assets/qr-code.jpg" alt="QR Code for Fee and Menu Details">
      </div>
    </div>
  </section>
  
<!-- Modal -->
<div class="modal fade" id="contactModal" tabindex="-1" role="dialog" aria-labelledby="contactModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="contactModalLabel">Contact Our Team</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Please contact our team to subscribe. We will be happy to assist you!</p>
        <h2>PhoneNumber: 8125977523</h2>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

  <!-- Update your subscription plans section -->
  <section class="payment-plans">
    <div class="container">
      <h2>Subscription Plans</h2>
  
      <div class="plan">
        <h3>Three Months Plan</h3>
        <p>₹3,600</p>
        <button class="btn" onclick="subscribePlan(3600)">Buy Now</button>
      </div>
  
      <div class="plan">
        <h3>Six Months Plan</h3>
        <p>₹7,000</p>
        <button class="btn" onclick="subscribePlan(7000)">Buy Now</button>
      </div>
  
      <div class="plan">
        <h3>One Year Plan</h3>
        <p>₹15,999</p>
        <button class="btn" onclick="subscribePlan(15999)">Buy Now  </button>
      </div>
    </div>
  </section>
  
  <!-- <div id="payment-section">
    <h2>Make Payment</h2>
    <p>Total Amount: {{ amount }}</p>
    <button onclick="openRazorpay()">Pay Now</button>
  </div> -->
  
  

<!-- WhatsApp icon -->
<a href="https://wa.me/8125977523" target="_blank" class="whatsapp-icon">
  <i class="fab fa-whatsapp"></i>
</a>



  <footer>
    <div class="container">
      <p>&copy; 2023 PG Hostel Management - unsofttech.in. All rights reserved.</p>
    </div>
  </footer>

