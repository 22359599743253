import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-paymentsuccess',
  templateUrl: './paymentsuccess.component.html',
  styleUrls: ['./paymentsuccess.component.css']
})
export class PaymentsuccessComponent implements OnInit {
  orderId!: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    console.log('PaymentsuccessComponent initialized.');
    this.route.queryParams.subscribe(params => {
      this.orderId = params['order_id'];
      console.log('Order ID:', this.orderId);
    });
  }
}
