<!-- <mat-toolbar style="height: 80px" class="fixed-toolbar">
  <div class="container">
    <h1 class="header">Admin Dashboard</h1>
  </div>
  <div class="right-items">
    <span class="spacer"></span>

    <button
      style="
        float: right;
        background-color: #f7faf8;
        color: rgb(10, 8, 8);
        margin-right: 15px;
        margin-top: 65px;
        margin-top: -20px;
        border-radius: 4px;
        border-color: rgb(245, 11, 11);
      "
      (click)="logoutUser()"

      mat-raised-button
      
    >
      Logout
    </button>

    <br />
  </div>
  <mat-toolbar-row class="row">
    <img src="\assets\un.jpg" alt="Logo" class="logo-image" />

    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<mat-sidenav-container>
  <mat-sidenav #sidenav mode="side">
    <br />
    <br />
    <mat-nav-list>
      <br />
      <br />
      <div class="mat-nav-list">
        <a [routerLink]="['../header/table']">Subscribers</a><br /><br />
      </div>
      <div class="mat-nav-list">
        <a [routerLink]="['../header/card']">ActiveSubscribers</a><br /><br />
      </div>
      <div class="mat-nav-list">
        <a [routerLink]="['../header/regist']">AddNewSubscriber</a><br /><br />
      </div>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <div style="height: 100vh; width: auto">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container> -->




<mat-toolbar class="fixed-toolbar">
  <button mat-icon-button (click)="nav.toggle()"><mat-icon class="custom-icon">menu</mat-icon></button>
  <span class="logo">Admin Dashboard</span>
<span class="extra"></span>
  <!-- <button class="arrow" mat-button [matMenuTriggerFor]="menu"><a class="user">{{user}}</a></button><i class="fa-solid fa-angle-down"></i> -->
  <button class="arrow" mat-button [matMenuTriggerFor]="menu">
    <div class="tag"><a class="user">{{ user }}</a>
    <i class="fa-solid fa-angle-down"></i></div>
  </button>
  
  <mat-menu #menu="matMenu" >
   <a ><button mat-menu-item (click)="logout()">Logout</button></a>
    <button mat-menu-item><a (click)="openPopUp()">Change Password</a></button>
  </mat-menu>
</mat-toolbar>

<mat-sidenav-container class="sidenav-wrapper" [ngStyle]="{'height.rem': 50}">
   <mat-sidenav mode="side" opened #nav class="sidenav">
   
     <div class="accordion" id="accordionExample" >
      
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button class="accordion-button collapsed custom-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
             <a [routerLink]="['../header/card']">
              Subscribers           
           </a>
            </button>
          </h2>
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button class="accordion-button collapsed custom-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
             <a [routerLink]="['../header/table']">
              ActiveSubscribers           
           </a>
            </button>
          </h2>
        
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button class="accordion-button collapsed custom-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
             <a [routerLink]="['../header/regist']">
              AddNewSubscriber           
           </a>
            </button>
          </h2>
        
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button class="accordion-button collapsed custom-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
             <a [routerLink]="['livedemo']">
              getLiveDemo          
           </a>
            </button>
          </h2>
        
        </div>

        <div class="accordion-item">
          <h2 class="accordion-header" id="headingSeven">
            <button class="accordion-button collapsed custom-class" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
             <a [routerLink]="['subscribe']">
              subscribe         
           </a>
            </button>
          </h2>
        
        </div>

     </div> 
    




 
   </mat-sidenav>
   <mat-sidenav-content class="custom-sidenav-content">
     <router-outlet></router-outlet>
    


  </mat-sidenav-content>
 </mat-sidenav-container>

