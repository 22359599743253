<div class="main-container">
  <div class="business-card" *ngFor="let myBusiness of business">
    <h2 class="business-name">{{ myBusiness.businessName }}</h2>
    <p class="business-address">{{ myBusiness.address }}</p>
    <!-- Display other properties as needed -->
  </div>
</div>


<div class="columns">
  <div class="price" *ngFor="let room of rooms">
    <div class="header">{{ room.roomType }} Sharing</div>
    <div class="rent">{{ room.monthlyRent | currency:'INR':'symbol' }} / month</div>
    <div class="feature">Min Advance payment</div>
    <div class="feature">Unlimited Wifi</div>
    <div class="feature">24 hours Water Facility</div>
    <div class="feature">24/7 Electricity</div>
    <div class="feature">{{ room.bedsAvailable }} Sharing</div>
    <div class="contact"><a href="tel:{{ room.contactNumber }}" class="button">
          Contact Now: {{ room.contactNumber }}
        </a></div>


       

  </div>
</div>


