<!-- Add a welcome section -->
<div class="welcome-section"  *ngFor="let myBusiness of business">
  <h4>
    Welcome to
    <span class="highlight">{{ myBusiness.businessName }}</span>
  </h4>
 
  <p><span class="highlight">{{ myBusiness.address }}</span></p>
  <p>Discover a world of comfort and convenience at our establishment. Explore our offerings and make yourself at home.</p>
</div>

<!-- Room Information -->
<h1 class="text-center">FEE DETAILS</h1>
<div class="columns">
  <div class="price" *ngFor="let room of rooms">
    <div class="header">{{ room.roomType }} Sharing</div>
    <div class="rent">{{ room.monthlyRent | currency:'INR':'symbol' }} / month</div>
    <div class="feature">Min Advance payment</div>
    <div class="feature">Unlimited Wifi</div>
    <div class="feature">24 hours Water Facility</div>
    <div class="feature">24/7 Electricity</div>

<!-- Check if beds are available -->
<div class="beds" *ngIf="room.bedsAvailable !== null && room.bedsAvailable !== 0">
  <span class="available-beds">
    {{ room.bedsAvailable }} Bed{{ room.bedsAvailable !== 1 ? 's' : '' }} Available
  </span>
  <span class="hurry-up">Hurry up!</span>
</div>


<!-- Display message if no beds are available -->
<div class="beds" *ngIf="room.bedsAvailable === null || room.bedsAvailable === 0">
  <span class="no-beds">No beds are available for this sharing</span>
</div>



    <div class="contact">
      <a href="tel:{{ room.contactNumber }}" class="button">
        Contact Now: {{ room.contactNumber }}
      </a>
    </div>
  </div>
</div>

<!-- Menu Information -->
<h1 class="text-center">MENU ITEMS</h1>
<div class="menu-container">
  <cdk-accordion class="example-accordion">
    <ng-container *ngFor="let menuDay of menuData; let dayIndex = index;">
      <cdk-accordion-item
        [class.active]="expandedIndex === dayIndex"
        (click)="toggleAccordion(dayIndex)"
      >
      <div class="example-accordion-item-header">
        <div class="left-side">
          {{ menuDay.day }}
        </div>
        <div class="right-side">
          <span class="example-accordion-item-description">
            Click to {{ expandedIndex === dayIndex ? 'close' : 'open' }}
          </span>
        </div>
      </div>
        <div
          class="example-accordion-item-body"
          role="region"
          [style.display]="expandedIndex === dayIndex ? '' : 'none'"
        >
          <div class="menu-item" *ngFor="let menuItem of menuDay.menuItemsList">
            <div class="session">{{ menuItem.session }}</div>
            <div class="timing">{{ menuItem.timing }}</div>
            <span class="meal-name">{{ menuItem.itemName }}</span>
            <img [src]="getSafeImage(menuItem.itemImage)" alt="Menu Item Image" class="meal-image">
          </div>
        </div>
      </cdk-accordion-item>
    </ng-container>
  </cdk-accordion>
</div>
