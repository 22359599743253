<div class="dashboard-container">
  <div class="card-container">
    <mat-card class="dashboard-card">
      <mat-card-title>Total Candidates</mat-card-title>
      <mat-card-content>
        <div class="card-value-container">
          <p class="card-value">{{ totalCandidates }}</p>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="dashboard-card">
      <mat-card-title>Total Collections</mat-card-title>
      <mat-card-content>
        <div class="card-value-container">
          <p class="card-value">{{ totalCollections | currency:'INR':'symbol' }}</p>
        </div>
      </mat-card-content>
    </mat-card>

   <!-- New Card for Room and Fee Summary (Static Content) -->
   <!-- <mat-card class="dashboard-card">
    <mat-card-title>Room and Fee Summary</mat-card-title>
    <mat-card-content>
      <div class="card-value-container">
        <p class="card-value">100 Rooms</p>
        <p class="card-value">₹50,000 Collected</p>
      </div>
    </mat-card-content>
  </mat-card> -->
</div>
<div class="graph-container">
  <canvas id="dashboardGraph"></canvas>
</div>
</div>

<!-- <div class="upcoming-events-container">
<h2>Upcoming Events</h2>
<ul>
  <li>Event 1 - 01/20/2024</li>
  <li>Event 2 - 02/15/2024</li>
</ul>
</div>

<div class="recent-activities-container">
<h2>Recent Activities</h2>
<ul>
  <li>Activity 1 - 01/10/2024</li>
  <li>Activity 2 - 01/05/2024</li>
</ul>
</div>

<div class="quick-actions-container">
<button mat-button color="primary" [routerLink]="['/add-candidate']">Add New Candidate</button>
</div> -->