<div class="container naresh">
    <div class="title">Add Menu Details</div>
    <div class="content">
      <form [formGroup]="MenuForm" (ngSubmit)="onSubmit()">
        <div class="user-details">

        

          <div class="input-box">
            <span class="details">itemName</span>
            <input type="text" formControlName="itemName" required>
           
          </div>


      <div class="input-box">
        <span class="details">Session Type</span>
        <mat-select formControlName="session" placeholder="Select Session">
          <mat-option value="" disabled selected hidden class="white-text">Select Session Type</mat-option>
          <mat-option value="Morning">Morning</mat-option>
          <mat-option value="Afternoon">Afternoon</mat-option>
          <mat-option value="Night">Night</mat-option>
        </mat-select>
      </div>

      <div class="input-box">
        <span class="details">Timing Type</span>
        <input type="text" formControlName="timing" required placeholder="8:00AM - 11:00AM">
       
      </div>

          <div class="input-box">
            <span class="details">image</span>
            <input type="file" required formControlName="itemImage" (change)="onFileSelected($event)">
           
          </div>
  
         
      <div class="button">
        <input type="submit" value="Add Menu">
      </div>
      <button type="button" class="btn btn-secondary" (click)="onCloseClick()">Close</button>

      </div>
    </form>
    </div>
    </div>


