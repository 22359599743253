import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-send-emails',
  templateUrl: './send-emails.component.html',
  styleUrls: ['./send-emails.component.css']
})
export class SendEmailsComponent {
  isLoading = false;
  SendEmailForm!: FormGroup ;
 
  constructor(
    private formBuilder: FormBuilder,
    private candidateservice: ServicesService
  ) {}
    
  ngOnInit(): void {
    this.SendEmailForm = this.formBuilder.group({
      festival: [null,[Validators.required]], 
      businessId:[null,[Validators.required]] 
    });


  }

  onSubmit() {
    this.isLoading = true;
    setTimeout(() => {

    const formData: FormData = new FormData();
    formData.append('festival', this.SendEmailForm.get('festival')?.value);  // Add the 'festival' parameter
    formData.append('businessId', this.SendEmailForm.get('businessId')?.value);
    this.candidateservice.sendEmails(formData).subscribe(
      response => {
        console.log(response);
        Swal.fire({
          title: 'Festival emails sent successfully!',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        }).then(() => {
          this.SendEmailForm.reset();
          this.isLoading = false;
        });
      },
      error => {
        console.error('Error sending festival emails:', error);
        this.isLoading = false;
      }
    );
  }
    )
  }
}


