import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TableService {
  private url = 'https://api.careerneststay.in/my/findall';
  
  private url1 = 'https://api.careerneststay.in/my/find/';


  private DeleteUrl = 'https://api.careerneststay.in/my/deletebusiness/';

  constructor(private httpClient: HttpClient) {}

  
  getData(): Observable<any> {
    return this.httpClient.get<any>(`${this.url}`);
  }

  editData(businessId: string): Observable<any> {
    return this.httpClient.get<any>(`${this.url1}${businessId}`);
  }


  


  Delete(businessId: string): Observable<any> {
    return this.httpClient.delete<any>(`${this.DeleteUrl}${businessId}`);
  }
}
