import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-updatemenuitems',
  templateUrl: './updatemenuitems.component.html',
  styleUrls: ['./updatemenuitems.component.css']
})
export class UpdatemenuitemsComponent {
  menuItemData: any = {};
  selectedFile: File | null = null;
  updateForm!: FormGroup;
  itemId!: number; 


  constructor(
    public dialogRef: MatDialogRef<UpdatemenuitemsComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private formBuilder: FormBuilder,
  private sanitizer: DomSanitizer,
  private candidateservice: ServicesService
  ) {
    // Initialize the menuItemData object with data from the parent component
  this.menuItemData = data.menuItem;
  this.itemId = data.menuItem.menuItemId; // Correctly retrieve itemId from data

  
  }

  ngOnInit(): void {
     // Create the form group and form controls
     this.updateForm = this.formBuilder.group({
      itemName: [this.menuItemData.itemName, Validators.required],
      timing: [this.menuItemData.timing, Validators.required],
      session: [this.menuItemData.session, Validators.required],
      itemImage: [''], // You can add validation for the file input if needed
    });
    }


  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  getSafeImage(itemImage: string): SafeResourceUrl {
    // Assuming itemImage is a base64 encoded string
    const imageUrl = `data:image/png;base64,${itemImage}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }


updateMenuItem() {
  console.log('Updating menu item:', this.itemId, this.updateForm.value);
  if (this.updateForm.valid) {
    const formData: FormData = new FormData();
    formData.append('session', this.updateForm.get('session')?.value);
    formData.append('itemName', this.updateForm.get('itemName')?.value);
    formData.append('timing', this.updateForm.get('timing')?.value);

    if (this.selectedFile) {
      formData.append('itemImage', this.selectedFile);
    }

    if (this.itemId) {
      this.candidateservice.updateMenuItems(this.itemId, formData).subscribe(
        (response) => {
          console.log(response);
          Swal.fire({
            title: 'Data saved successfully!',
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
          }).then(() => {
            this.dialogRef.close(response);
          });
        },
        (error) => {
          console.error('Error:', error);
        }
      );
    } else {
      // Handle the case where itemId is not valid (e.g., show an error message)
    }
  }
}


  
}
