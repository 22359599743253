<form [formGroup]="addBankAccountForm" (ngSubmit)="onSubmit()">
    <div>
      <label for="accountHolderName">accountHolderName</label>
      <input type="text"  formControlName="accountHolderName" required>
    </div>
    <div>
      <label for="accountNumber">accountNumber</label>
      <input type="text"  formControlName="accountNumber" required>
      
    </div>
    <div>
      <label for="bankName">bankName</label>
      <input type="text" formControlName="bankName" required>
    </div>
    <div>
        <label for="ifscCode">ifscCode</label>
        <input type="text" formControlName="ifscCode" pattern="[A-Za-z]{4}\d{7}" required>
        <div *ngIf="addBankAccountForm.get('ifscCode')?.hasError('pattern')">Invalid IFSC Code pattern.</div>
      </div>
      
    <div>
      <button type="submit" [disabled]="!addBankAccountForm.valid">save</button>
    </div>
  </form>
  