import { HttpClient } from '@angular/common/http';
import { Component, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ServicesService } from '../services.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.css']
})
export class AddMenuComponent {
  selectedFile!: File;
  MenuForm!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private http: HttpClient,
    private candidateservice: ServicesService,
    public dialogRef: MatDialogRef<AddMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {  console.log('Received data in AddMenuComponent:', this.data);
}

  ngOnInit(): void {
    const dayId = this.data && this.data.id ? this.data.id : null;
    console.log('Received dayId in AddMenuComponent:', dayId);  // Check the received dayId
    this.MenuForm = this.formBuilder.group({
      dayId: [dayId, [Validators.required]], 
      itemName: [null, [Validators.required]],
      session: [null, [Validators.required]],
      timing: [null, [Validators.required]],
      itemImage: [''],
    });
  }
  
  

  onCloseClick(): void {
    this.dialogRef.close();
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  onSubmit() {
    const dayId = this.MenuForm.get('dayId')?.value;
  
    // Check if dayId is null or undefined
    if (dayId === null || dayId === undefined) {
      console.error('DayId is null or undefined.');
      // Handle the error, display an error message, etc.
      return;
    }


    const formData: FormData = new FormData();
    formData.append('itemImage', this.selectedFile);
    formData.append('dayId', dayId.toString());  // Ensure dayId is converted to a string
    formData.append('session', this.MenuForm.get('session')?.value);
    formData.append('itemName', this.MenuForm.get('itemName')?.value);
    formData.append('timing', this.MenuForm.get('timing')?.value);
  

    let businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
    this.candidateservice.saveMenuItem(dayId, businessId1,formData).subscribe(
      response => {
        console.log(response);
        Swal.fire({
          title: 'Data saved successfully!',
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
        }).then(() => {
          this.MenuForm.reset();
        });
      },
      error => {
        console.error('Error:', error);
        // Handle the error, display an error message, etc.
      }
    );
  }
  
}
}