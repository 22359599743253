import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from './login.service';
import { Login } from './login';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  showPassword: boolean = false;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private loginService: LoginService
  ) {}

  loginForm = this.formBuilder.group({
    businessId: [null, Validators.required],
    password: [null, [Validators.required, Validators.minLength(3)]],
  });

  ngOnInit(): void {}

  login() {
    const username = "admin";
    const password = "1234admin";
    const username1 = "usha";
    const password1 = "12345";



    if (this.loginForm.valid) {
      const formData: Login = {
        businessId: this.loginForm.value.businessId!,
        password: this.loginForm.value.password!,
      };
  
      if (
        (formData.businessId === username && formData.password === password) ||
        (formData.businessId === username1 && formData.password === password1)
      ) {
        localStorage.setItem("uname", formData.businessId);
        localStorage.setItem("password", formData.password);
        
        Swal.fire({
          icon: "success",
          title: "Admin Login Successfully",
          text: "Admin login successfully.",
          timer: 3000,
          showConfirmButton: false,
        }).then(() => {
          this.router
            .navigateByUrl("/", { skipLocationChange: true })
            .then(() => this.router.navigate(["/header"]));
        });
      }
      
      else{


      this.loginService.saveLogin(formData).subscribe(
        (token: string) => {
          // Token received, handle successful login
          localStorage.setItem('authToken', token);
          localStorage.setItem('businessId', formData.businessId);
          localStorage.setItem('password', formData.password);

          Swal.fire({
            icon: 'success',
            title: 'Login Successful',
            text: 'User login successful.',
            timer: 1000,
            showConfirmButton: false,
          }).then(() => {
            this.router
              .navigateByUrl('/', { skipLocationChange: true })
              .then(() => this.router.navigate(['/client-adminhome', formData.businessId]));
          });
        },
        (error: any) => {
          // Error occurred during login
          Swal.fire({
            icon: 'error',
            title: 'Invalid Credentials',
            text: 'Please enter valid credentials.',
            timer: 3000,
            showConfirmButton: false,
          });
        }
      );
    }
   } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Credentials',
        text: 'Please enter valid credentials.',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  }


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
    const passwordInput = document.getElementById('password') as HTMLInputElement;
    passwordInput.type = this.showPassword ? 'text' : 'password';
  }



  
  forgotPassword() {
  
  }
}