import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { UpdatemenuitemsComponent } from '../updatemenuitems/updatemenuitems.component';
import Swal from 'sweetalert2';

// menu-item.model.ts
export interface MenuItem {
  menuItemId: number;
  itemName: string;
  timing: string;
  session: string;
  itemImage: any;
}


export interface MenuDay {
  dayId: number;
  day: string;
  menuItemsList: MenuItem[];
}


@Component({
  selector: 'app-adminlistmenu',
  templateUrl: './adminlistmenu.component.html',
  styleUrls: ['./adminlistmenu.component.css']
})
export class AdminlistmenuComponent implements OnInit {
  menuItems: MenuItem[] = [];
  dayId!: number; // To store the dynamic dayId
  businessId: any;
  menuData: MenuDay[] = [];
  expandedIndex: number | null = null;
 
  
  constructor(
    private route: ActivatedRoute,
    private candidateservice: ServicesService,
    private http: HttpClient,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
  //   const businessId1 = localStorage.getItem("businessId");
  // if (businessId1 != null) {
  //   this.candidateservice.getAllMenusBusinessId(businessId1).subscribe((data) => {
  //     this.menuItems = data;  
  //   });
  // }

   this.getMenus();
  }

  private getMenus(){
    let businessId1 = localStorage.getItem("businessId");
  if (businessId1 != null) {
    this.candidateservice.getAllDaysByBusinessId(businessId1).subscribe((menuData => this.menuData = menuData));
   
}
  }

  editItem(menuItem: any, menuDay: any, itemIndex: number) {
    const dialogRef = this.dialog.open(UpdatemenuitemsComponent, {
      data: { menuItem: menuItem, itemId: menuItem.menuItemId },
    });
  
    dialogRef.afterClosed().subscribe(result => {
      // Handle the result if needed
      if (result) {
        // Refresh the menu data after an update
        this.getMenus();
      }
    });
  }
  
  deleteItem(itemId: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.candidateservice.deleteMenuItem(itemId).subscribe(
          () => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Your menu item has been deleted.',
              icon: 'success'
            });
            // Handle successful deletion, for example, refresh the menu data
            this.getMenus();
          },
          (error) => {
            console.error('Error deleting menu item:', error);
            Swal.fire({
              title: 'Error!',
              text: 'An error occurred while deleting the menu item.',
              icon: 'error'
            });
            // Handle error, show a message, etc.
          }
        );
      }
    });
  }

//   private getMenus(){
//     let businessId1 = localStorage.getItem("businessId");
//   if (businessId1 != null) {
//     this.candidateservice.getAllDaysByBusinessId(businessId1).subscribe((data)=>{
//       this.menuItems = data;
//     });
   
// }
//   }

  getSafeImage(itemImage: string): SafeResourceUrl {
    // Assuming itemImage is a base64 encoded string
    const imageUrl = `data:image/png;base64,${itemImage}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(imageUrl);
  }
  

  
  
}
