<br />
<br />
<br />
<br />
<div class="example-container mat-elevation-z8">

  <div class="container">

    <input [(ngModel)]="searchText" type="search"
      placeholder="Search by BusinessId/BusinessName/emailId\about\address etc" class="form-control"
      style="width: 100%; margin: 10px auto;"  (input)="applyFilter()"/>

      <table mat-table [dataSource]="dataSource" class="table table-hover" matSort>
        <!-- Position Column -->
      <ng-container matColumnDef="businessId">
        <th mat-header-cell *matHeaderCellDef>BusinessId</th>
        <td mat-cell *matCellDef="let element">{{ element.businessId }}</td>
      </ng-container>

      <!-- Name Column -->

      <ng-container matColumnDef="businessName">
        <th mat-header-cell *matHeaderCellDef>BusinessName</th>
        <td mat-cell *matCellDef="let element">{{element.businessName }}</td>
      </ng-container>

      <ng-container matColumnDef="businessType">
        <th mat-header-cell *matHeaderCellDef>BusinessType</th>
        <td mat-cell *matCellDef="let element">{{ element.businessType }}</td>
      </ng-container>

      <ng-container matColumnDef="emailId">

        <th mat-header-cell *matHeaderCellDef id="EmailId">Business EmailId</th>

        <td mat-cell *matCellDef="let element">{{ element.emailId }}</td>
      </ng-container>

      <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef>Password</th>
        <td mat-cell *matCellDef="let element">{{ element.password }}</td>
      </ng-container>

      <ng-container matColumnDef="phoneNo">
        <th mat-header-cell *matHeaderCellDef>PhoneNo</th>
        <td mat-cell *matCellDef="let element">{{ element.phoneNo }}</td>
      </ng-container>

      <ng-container matColumnDef="registrationDate">
        <th mat-header-cell *matHeaderCellDef>RegistrationDate</th>
        <td mat-cell *matCellDef="let element">{{ element.registrationDate }}</td>
      </ng-container>

      <ng-container matColumnDef="logo">
        <th mat-header-cell *matHeaderCellDef>Logo</th>
        <td mat-cell *matCellDef="let element">
          <img *ngIf="element.logo" [src]="getBase64Image(element.logo)" alt="Logo" height="50px" width="50px" />
        </td>
      </ng-container>

      <ng-container matColumnDef="viewQR">
        <th mat-header-cell *matHeaderCellDef>View</th>
        <td mat-cell *matCellDef="let element">
          <button color="primary" (click)="openDialog1(element.businessId)" mat-button>
            viewQR
          </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button color="primary" mat-icon-button (click)="openDialog(element.businessId)">
            <mat-icon> edit</mat-icon>
          </button>
          <button mat-icon-button color="warn" (click)="deleteRow(element.businessId)">
            <mat-icon>delete</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


    </table>
    <mat-paginator [pageSizeOptions]="[5, 10, 20]" [pageSize]="itemsPerPage" (page)="onPageChange($event)" [length]="dataSource.data.length"></mat-paginator>

  </div>


</div>