import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { HttpClient } from '@angular/common/http';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-room-numbers-modal-component',
  templateUrl: './room-numbers-modal-component.component.html',
  styleUrls: ['./room-numbers-modal-component.component.css']
})
export class RoomNumbersModalComponentComponent implements OnInit{
  RoomNumberForm!: FormGroup;
  id!: number;
  businessId!: string; 

  constructor(private servicesService: ServicesService, private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<RoomNumbersModalComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { id: number }  ){
     
    }

    ngOnInit(): void {
      this.id = this.data.id; // Extract the id from the data
      this.businessId = localStorage.getItem("businessId") || ''; // Assign the businessId
      this.initializeForm();
    }
    
  
  initializeForm() {
    this.RoomNumberForm = this.formBuilder.group({
      roomNumber: [null, Validators.required],
      // Add other form controls here
    });
  }


  saveRoomNumbers() {
    // Check if this.RoomNumberForm is not null or undefined
    if (this.RoomNumberForm) {
      // Assuming you have the roomNumber value available
      const roomNumber = this.RoomNumberForm.get('roomNumber')!.value;
  
      if (this.businessId) {
        const formData: FormData = new FormData();
        formData.append('roomNumber', roomNumber);
  
        this.servicesService.saveRoomNumbers(formData, this.id, this.businessId).subscribe(
          (response) => {
           // console.log(response);
            // Add success handling logic here
            // Close the form or navigate away after a successful save
            this.dialogRef.close();
  
            // Display success message
            Swal.fire({
              title: 'Success',
              text: 'Room numbers added successfully!',
              icon: 'success',
              timer: 3000, // Auto-close after 3 seconds
              showConfirmButton: false
            });
          },
          (error) => {
            console.error('Error adding room numbers', error);
  
            // Display error message
            Swal.fire({
              title: 'Error',
              text: 'Failed to add room numbers',
              icon: 'error'
            });
          }
        );
      } else {
        console.error('businessId is not available');
      }
    } else {
      console.error('RoomNumberForm is null or undefined');
    }
  }
  
}
