<!-- subscription.component.html -->
<div>
  <h2>Select a Subscription Plan</h2>

  <div *ngFor="let plan of subscriptionPlans" class="card" (click)="selectPlan(plan)" [class.selected]="plan === selectedPlan">
    <div>{{ plan }}</div>
    <div>Price: {{ pricing[plan.toLowerCase()] }} INR</div>
  </div>

  <button (click)="initiatePayment()">Initiate Payment</button>
</div>
