
import { CardService } from './card.service';
import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {
  count: number = 0; 

  constructor(private cardService: CardService) { }

  ngOnInit(): void {
    this.cardService.countData().subscribe(
      response => {
        this.count = response;
      },
      error => {
        console.error('Error retrieving count:', error);
      }
    );
  }

}
