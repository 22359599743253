<div class="container naresh">
  <div class="title">Send Mails</div>
  <div *ngIf="isLoading" class="loading-overlay">
    <div class="spinner"></div>
    <div class="loading-text">Sending mail please wait...</div>
  </div>
  <div class="content">
    <form [formGroup]="SendEmailForm" (ngSubmit)="onSubmit()">
      <div class="user-details">
        <div class="input-box">
          <textarea formControlName="festival" placeholder="send any kind of message here for your hostellers"></textarea>
        </div>

        <div class="input-box">
          <input formControlName="businessId" placeholder="BusinessId"/>
        </div>
       
        <div class="button" style="text-align: center; margin: 20px;">
          <button type="submit" [disabled]="isLoading" style="padding: 10px 20px; background-color: black; color: white; border: none; border-radius: 5px; cursor: pointer;">
            {{ isLoading ? 'Sending mail please wait...' : 'Send' }}
          </button>
        </div>
        
      </div>
    </form>
  </div>
</div>
