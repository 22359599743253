import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { LoginComponent } from "./Cart/login/login.component";
import { HeaderComponent } from "./header/header.component";
import { AuthGuard } from "./auth.guard";
import { TableComponent } from "./table/table.component";
import { CardComponent } from "./card/card.component";
import { RegistComponent } from "./regist/regist.component";
import { UserViewComponent } from "./user-view/user-view.component";
import { ClientAdminHomeComponent } from "./Clients/client-admin-home/client-admin-home.component";
import { AddcandidateComponent } from "./Clients/addcandidate/addcandidate.component";
import { AddFeeComponent } from "./Clients/add-fee/add-fee.component";
import { AddMenuComponent } from "./Clients/add-menu/add-menu.component";
import { ListfeeComponent } from "./Clients/listfee/listfee.component";
import { ListmenuComponent } from "./Clients/listmenu/listmenu.component";
import { ListcandidatesComponent } from "./Clients/listcandidates/listcandidates.component";
import { CandidateDetailComponent } from "./Clients/candidate-detail/candidate-detail.component";
import { TermsandconditionsComponent } from "./Clients/termsandconditions/termsandconditions.component";
import { GetpaymenthistoryComponent } from "./Clients/getpaymenthistory/getpaymenthistory.component";
import { AdminlistfeeComponent } from "./Clients/adminlistfee/adminlistfee.component";
import { UpdateroomComponent } from "./Clients/updateroom/updateroom.component";
import { AddDayComponent } from "./Clients/add-day/add-day.component";
import { SearchCandidatesComponent } from "./Clients/search-candidates/search-candidates.component";
import { SendEmailsComponent } from "./Clients/send-emails/send-emails.component";
import { AdminlistmenuComponent } from "./Clients/adminlistmenu/adminlistmenu.component";
import { UpdatemenuitemsComponent } from "./Clients/updatemenuitems/updatemenuitems.component";
import { IndexComponent } from "./index/index.component";
import { LivedemoComponent } from "./livedemo/livedemo.component";
import { MyMoneyComponent } from "./Clients/my-money/my-money.component";
import { SubscriptionComponent } from "./Clients/subscription/subscription.component";
import { PaymentsuccessComponent } from "./Clients/paymentsuccess/paymentsuccess.component";
import { RoomNumbersModalComponentComponent } from "./Clients/room-numbers-modal-component/room-numbers-modal-component.component";
import { BackupcandidatesComponent } from "./Clients/backupcandidates/backupcandidates.component";
import { DashboardComponent } from "./Clients/dashboard/dashboard.component";

const routes: Routes = [
  {path: '', component: IndexComponent},
  { path: "adminlogin", component: LoginComponent },

  { path: "", redirectTo: "user-view", pathMatch: "full" },
    { path: "user-view",component:UserViewComponent,pathMatch: "full"},
  { path: "login", component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  {
    path: "header",
    component: HeaderComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "table", component: TableComponent },
      { path: "card", component: CardComponent },
      { path: "regist", component: RegistComponent },
      { path: "livedemo", component: LivedemoComponent},
     // { path: 'subscribe', component: SubscriptionComponent },
    ],
  },
  {path: "card",component: CardComponent},
  { path: 'payment/success', component: PaymentsuccessComponent },
  {path: 'client-adminhome/:bussinessId', component: ClientAdminHomeComponent,
  children: [
    { path: "candidate", component: AddcandidateComponent },
    { path: "addfee", component: AddFeeComponent },
    { path: "addmenu", component: AddMenuComponent },
    { path: "addDay", component: AddDayComponent },
    {path: "listfee", component:ListfeeComponent},
    {path: "updateroom", component:UpdateroomComponent},
    {path: "listmenu", component: ListmenuComponent},
    { path: 'dashboard', component: DashboardComponent },
    {path: "adminlistmenu", component: AdminlistmenuComponent},
    {path: "updatemenuitems", component:UpdatemenuitemsComponent},
    {path: "listcandidate", component: ListcandidatesComponent},
    { path: 'candidates/:id', component: CandidateDetailComponent },
    { path: 'adminlistfee', component: AdminlistfeeComponent },
    { path: 'searchcandi', component: SearchCandidatesComponent },
    { path: 'sendmails', component: SendEmailsComponent },
    { path: 'mymoney', component: MyMoneyComponent },
    { path: 'roomnumber', component: RoomNumbersModalComponentComponent },
    { path: 'backup', component: BackupcandidatesComponent },
    { path: 'subscription/:businessId', component: SubscriptionComponent },
    { path: 'payment/success', component: PaymentsuccessComponent },
    { path: 'getpayment/:candidateId', component: GetpaymenthistoryComponent }

  ],
},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes), FormsModule],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export class routerOutlet {}

// { path: "user-view/:bussinessId",component:UserViewComponent,
// children: [
//   {path: "listfee", component: ListfeeComponent},
//   {path: "listmenu", component: ListmenuComponent},
//   {path: "termsandconditions", component: TermsandconditionsComponent}
// ]
// },


