import { Component, OnInit } from '@angular/core';
import { ServicesService } from '../services.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-backupcandidates',
  templateUrl: './backupcandidates.component.html',
  styleUrls: ['./backupcandidates.component.css']
})
export class BackupcandidatesComponent implements OnInit {
  searchText: string = '';
  backups: any[] = [];
  filteredBackups: any[] = [];
  businessId!: string;
  currentPage: number = 1;
  itemsPerPage: number = 8;

  constructor(private servicesService: ServicesService) {}

  ngOnInit(): void {

    const businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
    this.servicesService.getAllBackUpCandidates(businessId1).subscribe(
      (response: any[]) => {
        this.backups = response;
        this.filteredBackups = this.backups; // Initialize filteredBackups with all backups
      },
      error => {
        console.error(error);
      }
    );
  }
}

  filterBackups(): void {
    // Implement the filtering logic based on searchText
    this.filteredBackups = this.backups.filter(
      backup =>
        backup.fullName.toLowerCase().includes(this.searchText.toLowerCase()) ||
        backup.phoneNumber.includes(this.searchText) ||
        backup.email.toLowerCase().includes(this.searchText.toLowerCase())
      // Add more conditions for other columns as needed
    );
  }




  deletebackCandidate(id: number): void {
    Swal.fire({
      title: 'Are you sure you want to delete this candidate?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Call the API to delete the candidate
        this.servicesService.deleteBackupCandidate(id).subscribe(
          () => {
            Swal.fire({
              title: 'Deleted!',
              text: 'Candidate has been deleted.',
              icon: 'success',
            });
            // Refresh the list of backups after deletion
            this.refreshBackups();
          },
          (error) => {
            console.error(error);
            Swal.fire({
              title: 'Error',
              text: 'Failed to delete candidate.',
              icon: 'error',
            });
          }
        );
      }
    });
  }

  private refreshBackups(): void {
    // Call your API to get the latest list of backups
    // Update this.backups and this.filteredBackups
    // Example:
    // this.servicesService.getAllBackUpCandidates(this.businessId).subscribe(
    //   (response: any[]) => {
    //     this.backups = response;
    //     this.filteredBackups = this.backups;
    //   },
    //   error => {
    //     console.error(error);
    //   }
    // );
    const businessId1 = localStorage.getItem("businessId");
    if (businessId1 != null) {
    this.servicesService.getAllBackUpCandidates(businessId1).subscribe(
      (response: any[]) => {
        this.backups = response;
        this.filteredBackups = this.backups; // Initialize filteredBackups with all backups
      },
      error => {
        console.error(error);
      }
    );
  }
  }
}
