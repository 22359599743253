import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {

  totalCandidates: number = 0; // Set this value based on your data
  totalCollections: number = 0;
  isShowSkipButton: boolean = false;
  user: any;
  showNotification!: boolean;
  businessId!: string | null;
 

  constructor(private router: Router,private dialog: MatDialog,
    private http: HttpClient) {}
  
ngOnInit(){
  this.businessId = localStorage.getItem('businessId');
    this.user = this.businessId ? this.businessId : ''; 
    
    // Call getCandidateCount on component initialization
    this.getCandidateCount();
}

  getCandidateCount(): void {
    if (this.businessId) {
      this.http.get<any>(`https://api.careerneststay.in/api/countCandidates/${this.businessId}`).subscribe(
        (candidateCount) => {
          // Display the count directly without opening a modal
          this.totalCandidates = candidateCount;
        },
        (error) => {
          console.error('Error fetching candidate count', error);
        }
      );
    } else {
      // Handle the case where businessId is empty or not provided
      console.error('Business ID is empty or not provided');
    }
  }
  
 
  
  }
  
  
  