<div class="container naresh">
    <div class="title">Update Candidate</div>
    <div *ngIf="isLoading" class="loading-overlay">
      <div class="spinner"></div>
      <div class="loading-text">Submitting...</div>
    </div>
    <div class="content">
      
      <form [formGroup]="candidateForm" (ngSubmit)="onSubmit(data.id)">
        <div class="user-details">
  
          <div class="input-box">
            <span class="details">Full Name<sup class="sup">*</sup></span>
            <input type="text" formControlName="fullName" required>
            <div *ngIf="candidateForm.get('fullName')?.invalid && candidateForm.get('fullName')?.touched">
              <small class="error" *ngIf="candidateForm.get('fullName')?.errors?.['required']">fullName is required</small>
              <small class="error" *ngIf="candidateForm.get('fullName')?.errors?.['minlength']">fullName should have at least 4 characters</small>
              <small class="error" *ngIf="candidateForm.get('fullName')?.errors?.['maxlength']">fullName should have at most 50 characters</small>
              <small class="error" *ngIf="candidateForm.get('fullName')?.errors?.['pattern']">Invalid fullName</small>
            </div>
          </div>
  
          
          <div class="input-box">
            <label for="fatherName">Father Name<sup class="sup">*</sup></label>
            <input type="text" required ngModel  formControlName="fatherName">
            <div class="error" *ngIf="candidateForm.get('fatherName')?.invalid && candidateForm.get('fatherName')?.touched">
              <small class="error" *ngIf="candidateForm.get('fatherName')?.errors?.['required']">Father Name is required</small>
              <small class="error" *ngIf="candidateForm.get('fatherName')?.errors?.['minlength']">Father Name should have at least 4 characters</small>
              <small class="error" *ngIf="candidateForm.get('fatherName')?.errors?.['maxlength']">Father Name should have at most 50 characters</small>
              <small class="error" *ngIf="candidateForm.get('fatherName')?.errors?.['pattern']">Father Name should not contain numbers</small>
            </div>
          </div>
          
          
          <div class="input-box">
            <label for="phoneNumber">Phone Number<sup class="sup">*</sup></label>
            <input type="tel" required formControlName="phoneNumber">
            <div class="error" *ngIf="candidateForm.get('phoneNumber')?.invalid && candidateForm.get('phoneNumber')?.touched">
              <small class="error" *ngIf="candidateForm.get('phoneNumber')?.errors?.['required']">Please enter your phone number</small>
              <small class="error" *ngIf="candidateForm.get('phoneNumber')?.errors?.['pattern']">Please enter a valid 10-digit phone number starting with digits 6-9</small>
            </div>
          </div>
          
           
          <div class="input-box">
            <label for="aadhaarInput">Aadhaar Number<sup class="sup">*</sup></label>
            <input type="text" id="aadharnumber" required pattern="[0-9]{12}" formControlName="aadharNumber">
            <div  class="error" *ngIf="candidateForm.get('aadharNumber')?.invalid  && candidateForm.get('aadharNumber')?.touched ">
              <small class="error" *ngIf="candidateForm.get('aadharNumber')?.errors?.['required']">Please enter your Aadhaar number</small>
              <small class="error" *ngIf="candidateForm.get('aadharNumber')?.errors?.['pattern']">Please enter a valid 12-digit Aadhaar number</small>
            </div>
          </div>
  
        
            
            <div class="input-box">
              <span class="details">Address<sup class="sup">*</sup></span>
              <input type="text" required formControlName="address">
              <div  class="error" *ngIf="candidateForm.get('address')?.invalid  && candidateForm.get('address')?.touched ">
                <small  class="error"*ngIf="candidateForm.get('address')?.errors?.['required']"  class="sup">Address is required</small>
              </div>
            </div>
  
            <div class="input-box">
              <span class="details">email<sup class="sup">*</sup></span>
              <input type="email" required formControlName="email">
              <div  class="error" *ngIf="candidateForm.get('email')?.invalid  && candidateForm.get('email')?.touched ">
                <small  class="error"*ngIf="candidateForm.get('email')?.errors?.['required']"  class="sup">Address is required</small>
              </div>
            </div>
  
              
  <!-- City -->
  <div class="input-box">
    <span class="details">City</span>
    <input type="text" formControlName="city" placeholder="Optional">
  </div>
  
  <div class="input-box">
    <span class="details">Gender</span>
    <mat-select formControlName="gender">
      <mat-option value="" disabled selected hidden>Select Gender</mat-option>
      <mat-option value="male">Male</mat-option>
      <mat-option value="female">Female</mat-option>
      <mat-option value="other">Other</mat-option>
    </mat-select>
  </div>
  
  <!-- Marital Status -->
  <div class="input-box">
    <span class="details">Marital Status</span>
    <mat-select formControlName="maritalStatus">
      <mat-option value="" disabled selected hidden>Select Marital Status</mat-option>
      <mat-option value="single">Single</mat-option>
      <mat-option value="married">Married</mat-option>
    </mat-select>
  </div>
  
  
  
  
  
  
          <div class="input-box">
            <span class="details">Room Number<sup class="sup">*</sup></span>
            <input type="number" required formControlName="numberInput">
            <div class="error" *ngIf="candidateForm.get('numberInput')?.invalid && candidateForm.get('numberInput')?.touched">
              <small class="error" *ngIf="candidateForm.get('numberInput')?.errors?.['required']">Number is required</small>
              <small class="error" *ngIf="candidateForm.get('numberInput')?.errors?.['max']">Maximum 4 digits allowed</small>
            </div>
          </div>
  
          <!-- Sharing Type -->
          <div class="input-box">
            <span class="details">Sharing Type</span>
            <mat-select formControlName="sharing">
              <mat-option value="" disabled selected hidden class="white-text">Select Sharing Type</mat-option>
              <mat-option value="1">1</mat-option>
              <mat-option value="2">2</mat-option>
              <mat-option value="3">3</mat-option>
              <mat-option value="4">4</mat-option>
              <mat-option value="5">5</mat-option>
              <mat-option value="6">6</mat-option>
            </mat-select>
          </div>
  
          <div class="input-box">
            <span class="details">Joining Date<sup class="sup">*</sup></span>
            <input type="date"  formControlName="date" [disabled]="true">
            <div class="error" *ngIf="candidateForm.get('date')?.invalid && (candidateForm.get('date')?.dirty || candidateForm.get('date')?.touched)">
              <small class="error" *ngIf="candidateForm.get('date')?.errors?.['required']">Date is required</small>
            </div>
          </div>
  
          <div class="input-box">
            <label for="advance" class="details">Advance Payment<sup class="sup">*</sup></label>
           <input type="number" required formControlName="advance" class="no-spin">
            <div *ngIf="candidateForm.get('advance')?.invalid && candidateForm.get('advance')?.touched">
              <small class="error" *ngIf="candidateForm.get('advance')?.errors?.['required']">advance is required</small>
              <small class="error" *ngIf="candidateForm.get('advance')?.errors?.['pattern']">advance should be a number</small>
            </div>
          </div>
  
          <div class="input-box">
            <label for="fee" class="details">Monthly Fee Payment<sup class="sup">*</sup></label>
            <input type="number" required formControlName="fee" class="no-spin">
            <div *ngIf="candidateForm.get('fee')?.invalid && candidateForm.get('fee')?.touched">
              <small class="error" *ngIf="candidateForm.get('fee')?.errors?.['required']">Fee is required</small>
              <small class="error" *ngIf="candidateForm.get('fee')?.errors?.['pattern']">Fee should be a number</small>
            </div>
          </div>
          
          <div class="input-box">
            <span class="details">Add AddharCard</span>
            <input type="file" formControlName="file" (change)="onFileSelected($event)">
          </div>
          
    
      </div>
  
      <!-- <div class="button">
        <input type="submit" value="Register">
      </div> -->
      <div class="button">
        <button type="submit" [disabled]="isLoading">
          {{ isLoading ? 'Submitting...' : 'Update' }}
        </button>
      </div>
    
    </form>
    </div>
    </div>