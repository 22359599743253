<form [formGroup]="RoomNumberForm" class="room-number-form">
    <div class="input-box">
      <span class="details">Room Number<sup class="sup">*</sup></span>
      <input type="text" formControlName="roomNumber" required>
    </div>
  
    <div class="button">
      <button type="button" class="save-button" (click)="saveRoomNumbers()">Save Room Numbers</button>
    </div>
  </form>
  